import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./PromoteMyContent.css";
import StoreContext from "../../store/storecontext";

const PromoteMyContentTabs = () => {
  const [formActive, setFormActive] = useState("bb");
  const [isAdmin, setIsAdmin] = useState(false);
  const store = useContext(StoreContext);
  useEffect(() => {
    const currentURL = window.location.pathname;
    switch (currentURL) {
      case "/promote/bestbet/request":
        setFormActive("bb");
        break;
      case "/promote/topiccard/request":
        setFormActive("tc");
        break;
      case "/promote/how-to/request":
        setFormActive("ht");
        break;
      case "/promote/topiccard/custom/request":
        setFormActive("ctc");
        break;
      case "/promote/synonyms/request":
        setFormActive("synonyms");
        break;
      default:
        setFormActive("bb");
        break;
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsAdmin(store.state.isAdmin);
    }, 4000);
  }, []);

  const resetPromoteMyContentTabs = () => {
    try {
      let myActiveTab = document.querySelector(".nav-link.active");
      myActiveTab.classList.remove("active");
    } catch (error) {}
  };

  const tabActions = (form) => {
    resetPromoteMyContentTabs();

    if (form === "ctc") {
      let ctcTab = document.querySelector("#ctc_link");
      ctcTab.classList.add("active");
      setFormActive(form);
    } else {
      setFormActive(form);
    }
  };

  return (
    <div className="promote_page">
      <div className="menu_links">
        <ul className="nav">
          <li className="nav-item">
            <Link
              to="./bestbet/request"
              className={"nav-link " + (formActive === "bb" ? "active" : "")}
              aria-current="page"
              id="bb_link"
              onClick={() => tabActions("bb")}
            >
              Request Best Bet
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="./topiccard/request"
              className={"nav-link " + (formActive === "tc" ? "active" : "")}
              id="tc_link"
              onClick={() => tabActions("tc")}
            >
              Request Topic Card
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="./how-to/request"
              className={"nav-link " + (formActive === "ht" ? "active" : "")}
              id="howto_link"
              onClick={() => tabActions("ht")}
            >
              Request How-To
            </Link>
          </li>
          {isAdmin && (
            <>
              <li className="nav-item">
                <Link
                  to="./topiccard/custom/request"
                  className={
                    "nav-link " + (formActive === "ctc" ? "active" : "")
                  }
                  id="ctc_link"
                  onClick={() => tabActions("ctc")}
                >
                  Custom Topic Card
                </Link>
              </li>
            </>
          )}
          <li className="nav-item">
            <Link
              to="./synonyms/request"
              className={
                "nav-link " + (formActive === "synonyms" ? "active" : "")
              }
              id="synonyms_link"
              onClick={() => tabActions("synonyms")}
            >
              Request Synonym
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PromoteMyContentTabs;
