import { createBrowserRouter } from "react-router-dom";

import App from "../App";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import ManageRoles from "../components/ManageRoles/ManageRoles";
import HomePage from "../components/HomePage/HomePage";
import PromoteMyContent from "../components/PromoteLinks/PromoteMyContent";
import ManageMyRequests from "../components/ManageMyRequests/ManageMyRequest";
import ReviewContent from "../components/ReviewContent/ReviewContent";
import BBForm from "../components/Forms/BBForm/BBForm";
import TCForm from "../components/Forms/TCForm/TCForm";
import CTCForm from "../components/Forms/CTCForm/CTCForm";
import HowToForm from "../components/Forms/HowToForm/HowToForm";
import SynonymsForm from "../components/Forms/SynonymsForm/SynonymsForm";
import Searcher from "../components/ContentSearcher/Searcher";
import ContentReports from "../components/ContentReports/ContentReports";
import Metrics from "../components/Metrics/Metrics";
import FeedbackMetricsSection from "../components/Metrics/FeedbackMetrics/FeedbackMetricsSection";
import MetricsTabs from "../components/Metrics/MetricsTabs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <HomePage />
      },
      {
        path: "/managerole",
        element: <ManageRoles />
      },
      {
        path: "/promote",
        element: <PromoteMyContent />,
        children: [
          {
            path: "/promote/bestbet/request",
            element: <BBForm />
          },
          {
            path: "/promote/topiccard/request",
            element: <TCForm />
          },
          {
            path: "/promote/topiccard/custom/request",
            element: <CTCForm />
          },
          {
            path: "/promote/synonyms/request",
            element: <SynonymsForm />
          },
          {
            path: "/promote/how-to/request",
            element: <HowToForm />
          }
        ]
      },
      {
        path: "/myrequests",
        element: <ManageMyRequests />
      },
      {
        path: "/requests",
        element: <ReviewContent />
      },
      {
        path: "/promote/bestbet/request/:id",
        element: <BBForm />
      },
      {
        path: "/promote/topiccard/request/:id",
        element: <TCForm />
      },
      {
        path: "/promote/topiccard/custom/request/:id",
        element: <CTCForm />
      },
      {
        path: "/promote/synonyms/request/:id",
        element: <SynonymsForm />
      },
      {
        path: "/promote/how-to/request/:id",
        element: <HowToForm />
      },
      {
        path: "/search",
        element: <Searcher />
      },
      {
        path: "/reports",
        element: <ContentReports />
      },
      {
        path: "/metrics",
        element: <MetricsTabs />,
        children: [
          {
            path: "/metrics",
            element: <Metrics />
          },
          {
            path: "/metrics/feedback",
            element: <FeedbackMetricsSection />
          }
        ]
      },
      {
        path: "*",
        element: <PageNotFound />
      }
    ]
  }
]);

export default router;
