

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as CommentTracker from "../FormUI/CommentHistory/CommentTracker.js";
import ModalCustom from "../../Modal/ModalCustom.jsx";
import FormButtons from "../FormUI/FormButtons";

const TCButtonsWrapper = ({
    reqType,
    formName,
    idFromUrl,
    disableFormButtons,
    disableDraftButton,
    cssTheme,
    appStore,
    form,
    originalData,
    showPreviewBool,
    setPreviewContent,
    callCompareTable,
    showLoadingSpinner,
    setModalProps
  }) => {

    const btnServices = appStore.services;
    const [isAdmin, setIsAdmin] = useState(appStore.state.isAdmin);
    const [intID, setIntID] = useState(null);
    const [btnBools, setBtnBools] = useState({
        bShowCompareButton: null,
        bShowPreviewButton: null,
        bShowSaveChangesButton: null,
        bShowCancelRequestButton: null,
        bShowRemoveButton: null,
        bShowDraftButton: null,
        bShowRejectButton: null
    });

    const [cancelRequestModalMessage, setCancelRequestModalMessage] = useState("");
    const [openCancelRequestModal, setOpenCancelRequestModal] = useState(false);

    const navigate = useNavigate();

    const showCompareTable = (e) => {
        e.preventDefault();
        callCompareTable();
    };


    const showPreview = async (e) => {
      e.preventDefault();
      showLoadingSpinner(true, true);
      let previewBody = await buildRequestJson("New");
      let tcpreview = await appStore.services.topicCardService.GenerateHtmlContent(
        previewBody
      );

      try {
        setPreviewContent(JSON.parse(tcpreview.data));
      } catch (error) {
        setPreviewContent(tcpreview.data);
      }
      showLoadingSpinner(false, true);
      showPreviewBool();
    };

    const getSubmissionMessage = (status, id) => {
        let submissionMessage = "";
        let thisRequest = `Topic Card request ID #${id}`;
        if (status === "Implemented") {
          submissionMessage = `${thisRequest} has been saved and implemented. You may verify its implementation on Search.`;
        } else {
          submissionMessage = `${thisRequest} was successfully submitted. Upon clicking OK, you will be redirected to the "Submitted requests" view where you can track the status of this request.`;
        }
    
        return submissionMessage;
    };

    const createYammerThread = async (
        rqstBody,
        commentMsg = null,
        isSaveChanges = null
      ) => {
        let requestType;
        let title;
        let requestId;
        let requestIndex;
        let usersToTag;
        let delegatesToPost =
          " [[user:" +
          form.delegates.value.map((delegate) => delegate.userid).join("]]  [[user:") +
          "]]";
    
        requestType = "Topic Card";
        title = rqstBody.title;
        requestId = rqstBody.tcrequestid;
        requestIndex = process.env.REACT_APP_TCREQUEST_IDX;
        let tagOwnerOnYammer = `[[user:${rqstBody.submittedbyuserid}]]`;
        usersToTag =
          tagOwnerOnYammer +
          " " +
          (form.delegates.value.length > 0 ? delegatesToPost : "");
    
        let streamBody = [];
    
        let msg;
        let commentData = {
          tagOwnerOnYammer: tagOwnerOnYammer,
          delegatesToPost: form.delegates.value.length > 0 ? delegatesToPost : "",
          isAdmin: isAdmin,
          tcrequestid: requestId,
          title: title
        };
    
        if (isAdmin && !commentMsg) {
          msg = btnServices.yammerService.TopicCardComments("admin-implement", commentData);
        } else {
          msg = btnServices.yammerService.TopicCardComments("initial", commentData);
        }
    
        streamBody.push(msg);
        streamBody.push(commentMsg);
        let postComment = commentMsg != null;
        await btnServices.yammerService.PostToYammer(
          requestId,
          requestIndex,
          "_doc",
          postComment,
          streamBody
        );
    };

    const postTopicCardInYammer = async (rqstBody, tcBody) => {
        let streamBody = [];
    
        let delegatesToPost =
          " [[user:" +
          form.delegates.value.map((delegate) => delegate.userid).join("]]  [[user:") +
          "]]";
    
        let tagOwnerOnYammer = `[[user:${rqstBody.submittedbyuserid}]]`;
        let commentData = {
          tagOwnerOnYammer: tagOwnerOnYammer,
          delegatesToPost: rqstBody.contacts.length > 0 ? delegatesToPost : "",
          isAdmin: isAdmin,
          tcrequestid: rqstBody.tcrequestid,
          title: rqstBody.title
        };
    
        let commentMsg = btnServices.yammerService.TopicCardComments("implement", commentData);
        //yAMMER Post when an Admin Implements a Topic Card Request
        if (
          !rqstBody.yammerthreadid &&
          rqstBody.creationdate === rqstBody.lastmodificationdate
        ) {
          let postMsg = btnServices.yammerService.TopicCardComments("initial", commentData);
    
          streamBody.push(postMsg);
          streamBody.push(commentMsg);
          // PostToYammer(requestId, index, type, isI, rqstBody)
          btnServices.yammerService
            .PostToYammer(
              rqstBody.tcrequestid,
              process.env.REACT_APP_TCREQUEST_IDX,
              "_doc",
              JSON.stringify(tcBody) !== "{}",
              streamBody
            )
            .then(() => {})
            .catch((error) => {
              console.error("Yammer thread couldn't be posted: " + error);
            });
        }
        //Yammer Post when an Admin Implements a Topic Card Request
        else if (rqstBody.yammerthreadid) {
          streamBody.push(commentMsg);
          btnServices.yammerService
            .PostYammerComment(rqstBody.yammerthreadid, streamBody)
            .catch((error) => {
              console.error(
                "The comment couldn't be posted, method PostTopicCardInYammer: " +
                  error
              );
            });
        } else {
          await createYammerThread(rqstBody);
        }
    };

    const PostCommentsInTheTracker = (request) => {
        let user = localStorage.getItem("searchtools.eid");
        let comments = [];
        let delegatesComments = [];
        request.user = user;
    
        comments = CommentTracker.PostComments(request);

        if (idFromUrl) {
            let contactsArr = [];

            for (let cnta = 0; cnta < form.delegates.value.length; cnta++) {
                const contactExp = form.delegates.value[cnta];
                contactsArr.push(contactExp.eid);
            }
            let delegatesHaveChanged =
                JSON.stringify(contactsArr) !==
                JSON.stringify(originalData.tcReqData.contacts);
        
            if (form.delegates.value.length > 0 && delegatesHaveChanged) {
                let delegatesInfo = {
                originalDelegates: originalData.tcReqData.contacts,
                newDelegates: contactsArr,
                user: request.user,
                formType: "Topic Card"
                };
                delegatesComments = CommentTracker.PostDelegatesComments(delegatesInfo);
                comments.unshift(delegatesComments);
            }
        }
    
        if (form.commentBox.value !== "") {
          comments.unshift(CommentTracker.SaveComment(form.commentBox.value, user));
        }
        return comments;
    };


    const formHasUpdates = () => {
        let originalLineItems = originalData.tcReqData.body;

        return (
          form.title.value !== originalData.tcReqData.title ||
          form.url.value !== originalData.tcReqData.url ||
          form.description.value !== originalData.tcReqData.description ||
          JSON.stringify(form.refinerPages.value) !== JSON.stringify(originalData.tcReqData.aid) ||
          JSON.stringify(form.countries.value) !==
            JSON.stringify(originalData.tcReqData.country) ||
          JSON.stringify(form.keywordsObj.keywords.value.sort()) !==
            JSON.stringify(originalData.tcReqData.keywordsraw.sort()) ||
          JSON.stringify(form.keywordsObj.keywordsforreview.value) !==
            JSON.stringify(originalData.tcReqData.invalidkeywordsraw) ||
          JSON.stringify(form.supportContent) !==
            JSON.stringify(originalLineItems.getsupport) ||
          JSON.stringify(form.sitesNews) !==
            JSON.stringify(originalLineItems.newsandarticles) ||
          JSON.stringify(form.learningMaterials) !==
            JSON.stringify(originalLineItems.learn) ||
          JSON.stringify(form.salesMaterial) !==
            JSON.stringify(originalLineItems.salesmaterial) ||
          JSON.stringify(form.accenturePolicies) !==
            JSON.stringify(originalLineItems.accenturepolicies) ||
          JSON.stringify(form.internalResources) !==
            JSON.stringify(originalLineItems.internalresources) ||
          JSON.stringify(form.externalResources) !==
            JSON.stringify(originalLineItems.externalresources)
        );
    };


    const postYammerTopicCardSaveChanges = async (rqstBody) => {
      let delegatesToPost =
        " [[user:" +
        form.delegates.value.map((delegate) => delegate.userid).join("]]  [[user:") +
        "]]";
  
      let tagOwnerOnYammer = `[[user:${rqstBody.submittedbyuserid}]]`;
  
      let commentData = {
        tagOwnerOnYammer: tagOwnerOnYammer,
        delegatesToPost: rqstBody.contacts.length > 0 ? delegatesToPost : "",
        isAdmin: isAdmin,
        tcrequestid: parseInt(rqstBody.tcrequestid),
        title: rqstBody.title
      };
      let commentMsg = btnServices.yammerService.TopicCardComments(
        "save-changes",
        commentData
      );
  
      let bodyComment = [];
  
      bodyComment.push(commentMsg);
      let arrStatus = ["Cancelled", "Not Approved", "Implemented", "New"];
      //Yammer Post when a User Updates a Topic Card Request
      if (formHasUpdates()) {
        if (rqstBody.yammerthreadid) {
          btnServices.yammerService
            .PostYammerComment(rqstBody.yammerthreadid, bodyComment)
            .catch((error) => {
              console.error(
                "The comment couldn't be posted, method PostYammerComment: " +
                  error
              );
            });
        } else if (arrStatus.includes(form.status)) {
          await createYammerThread(rqstBody, commentMsg, true);
        }
      }
    };


    const postRemoveTcCommentInYammer = async (rqstBody, isTc) => {
      let body = [];
      let commentMessage = "";
  
      let tagOwnerOnYammer = `[[user:${form.submittedbyuserid}]]`;
      let delegatesToPost =
        " [[user:" +
        form.delegates.value.map((delegate) => delegate.userid).join("]]  [[user:") +
        "]]";
      let contacts = form.contacts;
  
      let commentData = {
        tagOwnerOnYammer: tagOwnerOnYammer,
        delegatesToPost: contacts.length > 0 ? delegatesToPost : "",
        isAdmin: isAdmin,
        tcrequestid: rqstBody.tcrequestid,
        title: rqstBody.title
      };
      switch (rqstBody.status) {
        case "Cancelled":
          commentMessage = btnServices.yammerService.TopicCardComments("cancel", commentData);
          break;
        case "Inactive":
          commentMessage = btnServices.yammerService.TopicCardComments("remove", commentData);
          break;
        case "Not Approved":
          commentMessage = btnServices.yammerService.TopicCardComments("reject", commentData);
          break;
      }
  
      if (isTc) {
        body.push(commentMessage);
        if (!rqstBody.yammerthreadid) {
          await createYammerThread(rqstBody, commentMessage);
        } else {
          await btnServices.yammerService
            .PostYammerComment(rqstBody.yammerthreadid, body)
            .catch((error) => {
              console.error("The comment couldn't be posted: " + error);
            });
        }
      } else {
        body.push(commentMessage);
        await btnServices.yammerService
          .PostYammerComment(rqstBody.yammerthreadid, body)
          .catch((error) => {
            console.error(
              "The comment couldn't be posted, method PostYammerComment: " + error
            );
          });
      }
    };

    const buildRequestJson = async (status, action = null) => {
        let user = localStorage.getItem("searchtools.eid");
        let currentDateTime = new Date();
        let currentUTCDate = currentDateTime.toISOString();
        const creationDate = form.creationdate
            ? form.creationdate
            : currentDateTime;
        //Checks if the form was updated in status Cancelled and Not Approved
        let isCancelledOrNotApproved =
            form.status === "Cancelled" || form.status === "Not Approved";

        let currentStatus = form.status;
        //Checks if essential updates were performed in the Topic Card Form (it's combined with the previous condition)
        if (isCancelledOrNotApproved && formHasUpdates()) {
            status = "New";
        } else if (form.tcrequestid && !formHasUpdates()) {
            status = currentStatus;
        } else if (action === "Request Topic Card") {
            status = "New";
        } else {
            status = action === "Save Changes" ? "New" : form.status;
        }

        let submittedBY = "";
        let submittedByPk = "";
        let currentUserId = null;

        if (
            form.submittedbyenterpriseid !== null &&
            form.submittedbyenterpriseid.length > 0
        ) {
            submittedBY = form.submittedbyenterpriseid;
            submittedByPk = form.submittedbypeoplekey;
            currentUserId = await btnServices.yammerService.GetUserIdYammer(
            form.submittedbyenterpriseid
            );
        } else {
            submittedBY = user;
            submittedByPk = localStorage.getItem("searchtools.pk");
            currentUserId = await btnServices.yammerService.GetUserIdYammer(user);
        }

        let contactsArr = [];

        for (let cnta = 0; cnta < form.delegates.value.length; cnta++) {
            const contactExp = form.delegates.value[cnta];
            contactsArr.push(contactExp.eid);
        }
        

        let rqstBody = {
            title: form.title.value.trim(),
            url: form.url.value.trim(),
            description: form.description.value.trim(),
            body: {
            getsupport: form.supportContent.filter(
                (r) => r.title.trim() !== "" && r.url.trim() !== ""
            ),
            learn: form.learningMaterials.filter(
                (r) => r.title.trim() !== "" && r.url.trim() !== ""
            ),
            newsandarticles: form.sitesNews.filter(
                (r) => r.title.trim() !== "" && r.url.trim() !== ""
            ),
            accenturepolicies: form.accenturePolicies.filter(
                (r) => r.title.trim() !== "" && r.url.trim() !== ""
            ),
            internalresources: form.internalResources.filter(
                (r) => r.title.trim() !== "" && r.url.trim() !== ""
            ),
            externalresources: form.externalResources.filter(
                (r) => r.title.trim() !== "" && r.url.trim() !== ""
            ),
            salesmaterial: form.salesMaterial.filter(
                (r) => r.title.trim() !== "" && r.url.trim() !== ""
            ),
            getintouch: form.getInTouch.value
            },
            aid: form.refinerPages.value,
            keywords: form.keywordsObj.keywords.value.map((kw) => {
            return kw.toLowerCase();
            }),
            keywordsraw: form.keywordsObj.keywords.value,
            invalidkeywords: form.keywordsObj.keywordsforreview.value.map((kw) => {
            return kw.toLowerCase();
            }),
            invalidkeywordsraw: form.keywordsObj.keywordsforreview.value,
            contacts: contactsArr,
            comments: form.comments,
            lastmodificationdate: currentUTCDate,
            creationdate: creationDate,
            history: form.history,
            expirationdate: null,
            status: status,
            submittedbyenterpriseid: submittedBY,
            submittedbypeoplekey: submittedByPk,
            submittedbyuserid: currentUserId.data,
            country: form.countries.value,
            delegates: form.delegates.value,
            organization: form.organization,
            tcid: form.tcid,
            yammerthreadid: form.yammerthreadid,
            tcrequestid: form.tcrequestid,
            template: "standard",
            wasdraft: form.wasdraft
        };
        rqstBody.history.push({
            status: status,
            user: localStorage.getItem("searchtools.eid"),
            dateUTC: currentUTCDate
        });
        let request = {
            action: action,
            status: action === "Implement" ? "Implemented" : status,
            reqType: null,
            requestId: idFromUrl
        };

        if (!idFromUrl) {
            rqstBody.comments = PostCommentsInTheTracker(request);
        } else if (request.action === "Save Changes") {
            if (formHasUpdates()) {
              
              let updateComments = PostCommentsInTheTracker(request).concat(
                  form.comments
              );

              rqstBody.comments = [...updateComments];

            } else {
              let user = localStorage.getItem("searchtools.eid");
              if (form.commentBox.value !== "") {
                  rqstBody.comments.unshift(
                  CommentTracker.SaveComment(form.commentBox.value, user)
                  );
              }
            }
        } else {
            rqstBody.comments = PostCommentsInTheTracker(request).concat(
            rqstBody.comments
            );
        }
        return rqstBody;
    };


    const SaveChanges = async (action, event) => {
      showLoadingSpinner(true, true);
      let currentUTCDate = new Date().toISOString();
      let body = [];
  
      if (action === "Save Changes" && !appStore.state.isSuperUser) {
        appStore.setShowFeedbackBox(1);
      }
  
      if (!disableFormButtons) {
        //Topic Card Request Status History
        let oldStatus = form.status;
        let rqstBody = await buildRequestJson(form.status, action);
        //the draft will not change status when updated
        if (action === "Save Draft") {
          rqstBody.status = "Draft";
        }
  
        rqstBody.history.push({
          status: rqstBody.status,
          user: sessionStorage.getItem("searchtools.eid"),
          dateUTC: currentUTCDate
        });
  
        body.push({});
        body.push(rqstBody);
  
        let SaveTcOnIndex = null;
        try {
          SaveTcOnIndex = await btnServices.topicCardService.SaveTopicCard(
            body,
            parseInt(form.tcrequestid),
            rqstBody.tcid
          );
        } catch (error) {
          appStore.setShowFeedbackBox(0);
        }
  
        await postYammerTopicCardSaveChanges(rqstBody);

        showLoadingSpinner(false, true);
        setModalProps(`Topic Card request ID # ${parseInt(form.tcrequestid)} was updated.`,() => navigate("/myrequests"));
      }
    };


    const submitTopicCard = async (status, event) => {
        let action = event.target.innerHTML;
        if (event.type === "click") {
          showLoadingSpinner(true, true);

          let topiccard = await buildRequestJson(status, action);
          if (action === "Save Draft") {
            topiccard.status = "Draft";
            topiccard.wasdraft = true;
          } else if (action === "Request Topic Card") {
            topiccard.status = "New";
          }
    
          if (action === "Request Topic Card" && !appStore.state.isSuperUser) {
            appStore.setShowFeedbackBox(1);
          }
    
          let tcBody = {};
          if (status === "Implemented") {
            let gs = form.supportContent.map((r) => r.url.trim());
            let lrn = form.learningMaterials.map((r) => r.url.trim());
            let nar = form.sitesNews.map((r) => r.url.trim());
            let ap = form.accenturePolicies.map((r) => r.url.trim());
            let ir = form.internalResources.map((r) => r.url.trim());
            let er = form.externalResources.map((r) => r.url.trim());
            let sls = form.salesMaterial.map((r) => r.url.trim());
    
            const urls = [
              ...gs.filter((r) => r !== ""),
              ...lrn.filter((r) => r !== ""),
              ...nar.filter((r) => r !== ""),
              ...ap.filter((r) => r !== ""),
              ...ir.filter((r) => r !== ""),
              ...er.filter((r) => r !== ""),
              ...sls.filter((r) => r !== "")
            ];
    
            //Collect all Titles
    
            gs = form.supportContent.map((r) => r.title.trim());
            lrn = form.learningMaterials.map((r) => r.title.trim());
            nar = form.sitesNews.map((r) => r.title.trim());
            ap = form.accenturePolicies.map((r) => r.title.trim());
            ir = form.internalResources.map((r) => r.title.trim());
            er = form.externalResources.map((r) => r.title.trim());
            sls = form.salesMaterial.map((r) => r.title.trim());
    
            const titles = [
              ...gs.filter((r) => r !== ""),
              ...lrn.filter((r) => r !== ""),
              ...nar.filter((r) => r !== ""),
              ...ap.filter((r) => r !== ""),
              ...ir.filter((r) => r !== ""),
              ...er.filter((r) => r !== ""),
              ...sls.filter((r) => r !== "")
            ];
    
            let preview = await btnServices.topicCardService.GenerateHtmlContent(
              topiccard
            );
    
            tcBody = {
              sthccontent: "",
              sthcsearchterms: topiccard.keywords,
              sthcAID: topiccard.aid,
              cleancountry: [...topiccard.country],
              urls: urls,
              titles: titles,
              contacts: topiccard.contacts,
              cleanstatus: "Active",
              title: form.title.value.trim(),
              cleanurl: form.url.value.trim(),
              cleandescription: form.description.value.trim(),
              cleankeywords: topiccard.keywords,
              cleankeywordsraw: topiccard.keywordsraw,
              cleaninvalidkeywords: topiccard.invalidkeywords,
              cleaninvalidkeywordsraw: topiccard.invalidkeywordsraw,
              creationdate: topiccard.creationdate,
              template: topiccard.template,
              delegates: form.delegates.value
            };
    
            try {
              tcBody.sthccontent = JSON.parse(preview.data);
            } catch (error) {
              tcBody.sthccontent = preview.data;
            }
          }
          let body = [tcBody, topiccard];
    
          let SaveTcOnIndex = null;
    
          try {
            SaveTcOnIndex = await btnServices.topicCardService.SaveTopicCard(
              body,
              parseInt(form.tcrequestid),
              parseInt(topiccard.tcid)
            );
          } catch (error) {
            console.log("error saving tc data");
            appStore.setShowFeedbackBox(0);
          }
    
          let newReqId = SaveTcOnIndex.data._id;
    
          topiccard.tcrequestid = newReqId;
    
          await postTopicCardInYammer(topiccard, tcBody);
    
          let submissionMessage = getSubmissionMessage(status, newReqId);
          if (action === "Save Draft") {
            submissionMessage = `Draft ${newReqId} has been saved successfully. Upon clicking OK, you will be redirected to the Manage my Request section where you can track the status of this request.`;
          }

          showLoadingSpinner(false, true);
          setModalProps(submissionMessage,() => navigate("/myrequests"));
        }
    };

    // called by the modal and the cancel/remove buttons
    const RemoveMethod = async (status, isCustomTc, event) => {
      let action = !event.target ? event : event.target.innerHTML;
      let currentUTCDate = new Date().toISOString();
      let tcRequest = {};
      let body = [];

      showLoadingSpinner(true, true);

      //Topic Card Request Status History
      form.history.push({
        status: status,
        user: sessionStorage.getItem("searchtools.eid"),
        dateUTC: currentUTCDate
      });

      tcRequest.doc = {};
      tcRequest.doc.title = form.title.value;
      tcRequest.doc.tcrequestid = form.tcrequestid;
      tcRequest.doc.lastmodificationdate = currentUTCDate;
      tcRequest.doc.expirationdate = currentUTCDate;
      tcRequest.doc.comments = form.comments;
      tcRequest.doc.history = form.history;
      tcRequest.doc.yammerthreadid = form.yammerthreadid;
      tcRequest.doc.contacts = form.contacts;
      tcRequest.doc.submittedbyenterpriseid = form.submittedbyenterpriseid;
      tcRequest.doc.status = status;
      let request = {
        action: action,
        status: status,
        reqType: null,
        requestId: idFromUrl
      };

      if (!idFromUrl) {
        tcRequest.doc.comments = PostCommentsInTheTracker(request);
      } else {
        tcRequest.doc.comments = PostCommentsInTheTracker(request).concat(
          tcRequest.doc.comments
        );
      }

      if (status === "Inactive") {
        let tc = {
          doc: {
            cleanstatus: "Inactive"
          }
        };
        body.push(tc);
      } else {
        let tc = {
          doc: {}
        };
        body.push(tc);
      }

      body.push(tcRequest);

      let r = null;
      try {
        r = await appStore.services.topicCardService.UpdateTopicCard(
          body,
          form.tcrequestid,
          form.tcid
        );
      } catch (error) {
        appStore.setShowFeedbackBox(0);
      }

      postRemoveTcCommentInYammer(tcRequest.doc, true);

      let modalMessage = "";
      if (status === "Inactive") {
        modalMessage = "Topic Card has been deleted.";
      } else {
        modalMessage =
          "Topic Card" +
          " request ID # " +
          r.data._id +
          " was " +
          status.toLowerCase();
      }

      showLoadingSpinner(false, true);
      setModalProps(modalMessage,() => navigate("/myrequests"));
    };

    const saveDraft = (e) => {
      if (parseInt(form.tcid) > 0 && parseInt(form.tcrequestid) > 0) {
        SaveChanges("Save Draft", e);
      } else {
        submitTopicCard("Draft", e);
      }
    };

    const RemoveTc = (pStatus, pIsCustomTc, event) => {
      
      if (pStatus === "Inactive") {
        setCancelRequestModalMessage("Do you want to remove this Topic Card? ");
        setOpenCancelRequestModal(!openCancelRequestModal);
      } else {
        if (pStatus === "Cancelled" && !appStore.state.isSuperUser) {
          appStore.setShowFeedbackBox(1);
        }
        RemoveMethod(pStatus, pIsCustomTc, event);
      }

    };


    const closeTheModal = () => {
      setOpenCancelRequestModal(!openCancelRequestModal);
    };
  
    const onClickCancelRequestModal = () => {
      closeTheModal();
      RemoveMethod("Inactive", false, "Remove Topic Card");
    };

    const bntActionsHandler = (e, _actionToExecute) => {
        switch (_actionToExecute) {
          
          case "Implement Request Btn":
              e.preventDefault();
              submitTopicCard("Implemented", e);
            break;
    
          case "Remove Request Btn":
              e.preventDefault();
              RemoveTc("Inactive", false, e);
            break;
    
          case "Submit New Request Btn":
              e.preventDefault();
              submitTopicCard("New", e)
            break;
    
          case "Save Changes Btn":
              e.preventDefault();
              SaveChanges("Save Changes", e);
            break;
    
          case "Cancel Request Btn":
              e.preventDefault();
              RemoveTc("Cancelled", false, e);
            break;
    
          case "Reject Btn":
              e.preventDefault();
              RemoveTc("Not Approved", false, e);
            break;
    
          case "Submit Draft Btn":
              e.preventDefault();
              saveDraft(e);
            break;
    
          case "Show Preview Btn":
            showPreview(e);
            break;
    
          case "Compare Table Btn":
            showCompareTable(e);
            break;
    
          default:
            break;
        }
    };


    useEffect(() => {
        // updates the isAdmin variable
        try {
          if (intID === null) {
            const nIntervId = setInterval(() => {
              try {
                let btnElm2 = document.querySelector("[id*='-implement-button']");
                if (btnElm2.classList.length > 0) {
                  clearInterval(nIntervId);
                  clearInterval(intID);
                }
              } catch (error) {}
    
              setIsAdmin(appStore.state.isAdmin);
            }, 700);
            setIntID(nIntervId);
          }
        } catch (error) {}
    }, []);


    useEffect(() => {
        
        let showRejectButton = false;
        let showCompareButton = false;
        let showPreviewButton = true;
        let showDraftButton = false;
        let showSaveChangesButton = false;
        let showCancelRequestButton = false;
        let showRemoveButton = false;
        let showRequestTcButton = false;

        let currEid = localStorage.getItem("searchtools.eid");
        let isOwner = currEid === form.submittedbyenterpriseid;
        let isDelegate = form.contacts.includes(currEid);

        if (isAdmin) {
            switch (form.status) {
            case "Implemented":
                showRemoveButton = true;
                showSaveChangesButton = true;
                break;

            case "New":
                if (parseInt(form.tcid) > 0) {
                    showRejectButton = true;
                    showCompareButton = true;
                    showSaveChangesButton = true;
                } else if (
                    parseInt(form.tcid) <= 0 &&
                    parseInt(form.tcrequestid) > 0 &&
                    parseInt(idFromUrl) > 0
                ) {
                    showRejectButton = true;
                    showSaveChangesButton = true;
                }
                break;

            case "Cancelled":
                showSaveChangesButton = true;
                break;

            case "Not Approved":
                showSaveChangesButton = true;
                break;

            default:
                break;
            }
        } else if (!isAdmin) {
            showDraftButton = true;
            switch (form.status) {
            case "Implemented":
                
                if (isOwner || isDelegate) {
                    showRemoveButton = true;
                    showSaveChangesButton = true;
                }
                break;

            case "New":
                if (parseInt(form.tcid) <= 0 && parseInt(form.tcrequestid) <= 0) {
                    showRequestTcButton = true;
                } else if (parseInt(form.tcid) <= 0 && parseInt(form.tcrequestid) > 0) {
                    if (isOwner || isDelegate) {
                        showCancelRequestButton = true;
                        showSaveChangesButton = true;
                    }
                } else if (parseInt(form.tcid) > 0 && parseInt(form.tcrequestid) > 0) {
                    if (isOwner || isDelegate) {
                        showCancelRequestButton = true;
                        showCompareButton = true;
                        showSaveChangesButton = true;
                    }
                }
                break;

            case "Draft":
                showRequestTcButton = true;
                if (parseInt(form.tcid) <= 0 && parseInt(form.tcrequestid) > 0) {
                    if (isOwner || isDelegate) {
                        showCancelRequestButton = true;
                    }
                } else if (parseInt(form.tcid) > 0 && parseInt(form.tcrequestid) > 0) {
                    if (isOwner || isDelegate) {
                        showCancelRequestButton = true;
                        showCompareButton = true;
                    }
                }

                break;
            case "Cancelled":
                showSaveChangesButton = true;
                break;

            case "Not Approved":
                showSaveChangesButton = true;
                break;

            default:
                break;
            }
        }
    
        let currBtnBools = {
          bShowPreviewButton: showPreviewButton,
          bShowDraftButton: showDraftButton,
          bShowRequestNewFormBtn: showRequestTcButton,
          bShowCancelRequestButton: showCancelRequestButton,
          bShowSaveChangesButton: showSaveChangesButton,
          bShowRejectButton: showRejectButton,
          bShowRemoveButton: showRemoveButton,
          bShowCompareButton: showCompareButton
        };
    
        setBtnBools({ ...currBtnBools });
    }, [form]);

    
    return (
        <>
          {openCancelRequestModal ? (
          <ModalCustom
            darkMode={cssTheme === "dark"}
            onClose={onClickCancelRequestModal}
            modal_msg={cancelRequestModalMessage}
            btn_1_class={
              cssTheme === "dark"
                ? "btn btn-dark-mode-purple btn-adjust-content-cancel"
                : "btn btn-light-mode-purple btn-adjust-content-cancel"
            }
            btn_2_class={
              cssTheme === "dark"
                ? "btn btn-dark-mode-purple btn-adjust-content-confirm-remove"
                : "btn btn-light-mode-purple btn-adjust-content-confirm-remove"
            }
            modalSize=""
            noHeader={true}
            secondOption={true}
            firstOptText={"Remove Topic Card"}
            onClick={onClickCancelRequestModal}
            secondOptText={"Cancel"}
            onClickSecondOpt={closeTheModal}
          />
        ) : (
          <div></div>
        )}
          <FormButtons
              reqType={reqType}
              formName={formName}
              disableFormButtons={disableFormButtons}
              disableDraftButton={disableDraftButton}
              cssTheme={cssTheme}
              isAdmin={isAdmin}
              btnBools={btnBools}
              bntActionsHandler={bntActionsHandler}
          />
        </>
    );
};

export default TCButtonsWrapper;