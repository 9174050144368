import { useParams } from "react-router";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../ThemeContext";
import StoreContext from "../../../store/storecontext";
import BB_Validations from "../../../validationClasses/BB_Validations";

import ModalCustom from "../../Modal/ModalCustom";
import ToastWithClose from "../../Toast/ToastWithClose";
import { triggerToast } from "../../Toast/TriggerToast";
import CompareTable from "./FormComponents/CompareTable";
import Preview from "../FormUI/Preview/Preview";
import BBPreview from "./FormComponents/BBPreview/BBPreview";
import NotFoundRequest from "../../PageNotFound/NotFoundRequest";

// form field components and css file
//  (always import the css file + all the needed components, use this as an example)
import "../FormUI/RequestForms.css";

// tooltip manager : this will initialize all the tooltips on the fields
import TooltipManager from "../FormUI/TooltipManager";

// gen ai methods
import GenAiDescription from "../FormUI/GenAIDescription/GenAiDescription";
// gen ai modal
import GenAiModal from "../FormUI/GenAIDescription/GenAiModal";

import FormSpinners from "../FormUI/FormSpinners";
import FormInfoHeader from "../FormUI/FormInfoHeader";
import TitleField from "../FormUI/FormFields/TitleField";
import UrlField from "../FormUI/FormFields/UrlField";
import UrlSuggestions from "../FormUI/UrlSuggestions/UrlSuggestions";
import DescriptionOrCommentField from "../FormUI/FormFields/DescriptionOrCommentField";
import CommentHistory from "../FormUI/CommentHistory/CommentHistory";
import LocationRefinerAndKeywords from "../FormUI/FormFields/LocationRefinerAndKeywords/LocationRefinerAndKeywords";
import BBButtonsWrapper from "./BBButtonsWrapper";
//-----

const BBForm = () => {
  const requestType = "BB";
  let { id } = useParams();
  const [idFromUrl, setIdfromUrl] = useState(id);
  const store = useContext(StoreContext);
  const [theme] = useContext(ThemeContext);
  const urlCheckerService = store.services.urlCheckerService;
  const bestBetService = store.services.bestBetService;
  const synonymsServiceRef = store.services.synonymsService;
  const genAIService = store.services.genIAService;
  const locRefKwfields = [
    "countries",
    "refinerPages",
    "keywords",
    "keywordsforreview"
  ];
  const locRefAndKwTooltipObj = {
    locationFieldTooltip:
      "Best Bet can be implemented as 'Global' to be visible by all Accenture employees or targeted to one or many locations.",
    refinerPageTooltip:
      "One or many Search pages (refiner pages) can be selected to display the Best Bet based on the site content and/or where the content resides (e.g., KX Documents, myLearning).",
    keywordsFieldTooltip: `Keyword recommendations:
    <br/>• At least 1 related keyword based on your site content is required; Maximum of 20 keywords can be entered. \n
    <br/>• Avoid generic terms that can be associated with various site content within Accenture, such as "Accenture", "skills", "process", or "analysis". \n
    <br/>• Avoid single keywords unless it is highly specific, such as an acronym. \n
    <br/>• Avoid keywords that have more than three words as it is very unlikely to be entered as a search term.`
  };
  const autoFillMessage = `This form has been prepopulated based on an existing Best Bet,
   please perform updates or request its removal below. To create a new Best Bet request, click Search Tools.`;

  const [showPreviewTrigger, setShowPreviewTrigger] = useState(false);
  const [compareTableTrigger, setCompareTableTrigger] = useState(false);
  const [originalReqData, setOriginalReqData] = useState({
    bbData: null,
    bbReqData: null
  });

  const [headerInfo, setHeaderInfo] = useState({
    showLastModified: null,
    showSecondSection: null,
    showInfoHeader: null
  });

  const [buttonsStates, setButtonsStates] = useState({
    disableFormButtons: null,
    disableDraftButton: null
  });

  const [disableFormFields, setDisableFormFields] = useState(false);
  const [disableRocketBtn, setDisableRocketBtn] = useState(true);
  const [displayGrayPills, setDisplayGrayPills] = useState(false);

  const [formImplementedModal, setFormImplementedModal] = useState({
    show: false,
    msg: "",
    onClose: () => {}
  });

  const closeFormImplementedModal = () => {
    setFormImplementedModal({ ...formImplementedModal, show: false }); //GO TO /myrequests;
    formImplementedModal.onClose();
  };

  const [urlSuggestions, setURLSuggestions] = useState({
    show: false,
    requests: []
  });

  const [showGenIADescModal, setShowGenIADescModal] = useState(false);
  const [showGenAiWarning, setShowGenAiWarning] = useState(false);
  const [lastGeneratedDescription, setLastGeneratedDescription] = useState("");
  const [lastGeneratedKeywords, setLastGeneratedKeywords] = useState();

  const [notFoundMessage, setNotFoundMessage] = useState(false);
  const [isFormWithData, setIsFormWithData] = useState(false);
  const [selectedGenAiOption, setSelectedGenAiOption] = useState("");
  const [isGenAiLoadingData, setIsGenAiLoadingData] = useState(true);
  const [showGenAiOptions, setShowGenAiOptions] = useState(false);

  const formData = {
    bbid: undefined,
    requestId: 0,
    url: { value: "", isValid: null },
    title: { value: "", isValid: null },
    description: { value: "", isValid: null },
    commentBox: { value: "", isValid: null },
    comments: [],
    countries: { value: [], isValid: null },
    refinerPages: { value: [], isValid: null },
    acronyms: [],
    keywordsObj: {
      keywords: { value: [], isValid: null },
      keywordsforreview: { value: [], isValid: null }
    },
    cleancreationdate: undefined,
    lastmodifieddateUTC: undefined,
    lastmodifiedby: [],
    requestedby: undefined,
    cleanstatus: "New",
    cleanemployeenumber: undefined,
    cleanrequesttype: "Creation",
    isDraftMode: false,
    yammerthreadid: null,
    qualityscore: 0
  };
  const [bbForm, setBbForm] = useState(formData);

  const updateFormState = (
    _field,
    _userInput,
    _validationResult,
    _replaceWholeArr = null
  ) => {
    let tempForm = { ...bbForm };

    if (
      _field === "keywords" ||
      _field === "keywordsforreview" ||
      _field === "keywordsObj"
    ) {
      if (_field === "keywordsObj") {
        // set data
        tempForm.keywordsObj.keywords.value = _userInput.greenKwList;
        tempForm.keywordsObj.keywordsforreview.value = _userInput.redKwList;

        // set field state
        tempForm.keywordsObj.keywords.isValid =
          _userInput.greenKwList.length > 0;
        tempForm.keywordsObj.keywordsforreview.isValid =
          _userInput.redKwList.length > 0;
      } else {
        // set data
        if (_replaceWholeArr === true) {
          tempForm.keywordsObj[_field].value = _userInput;
        } else if (_replaceWholeArr === null) {
          tempForm.keywordsObj[_field].value.push(_userInput);
        }

        //set field state
        if (_validationResult.hasOwnProperty("isValid")) {
          tempForm.keywordsObj[_field].isValid = _validationResult.isValid;
        } else {
          tempForm.keywordsObj[_field].isValid = _validationResult;
        }
      }
    } else {
      // set data
      tempForm[_field].value = _userInput;

      // set field state
      if (_validationResult.hasOwnProperty("isValid")) {
        tempForm[_field].isValid = _validationResult.isValid;
      } else {
        tempForm[_field].isValid = _validationResult;
      }
    }

    setBbForm({ ...tempForm });
  };

  const showPreview = () => {
    setShowPreviewTrigger(true);
  };

  const closeBbPreview = () => {
    setShowPreviewTrigger(false);
  };

  const showLoadingSpinner = (_showSpinner, _isOverlaySpinner = null) => {
    // shows spinner and hides form, or viceversa
    let spinnerEl = null;

    if (_isOverlaySpinner) {
      spinnerEl = document.querySelector(".overlay-spinner-selector");
      spinnerEl.style.visibility = _showSpinner ? "visible" : "hidden";
    } else {
      spinnerEl = document.querySelector(".main-form-loading-spinner");
      spinnerEl.style.visibility = _showSpinner ? "visible" : "hidden";

      let mainFormEl = document.querySelector(".form-main-div");
      mainFormEl.style.visibility = _showSpinner ? "hidden" : "visible";

      GenAiDescription.showRocketBtn(!_showSpinner);
    }
  };

  const showKwLoadingSpinner = (_showSpinner) => {
    let spinnerEl = document.querySelector(".keywords-loading-spinner");
    spinnerEl.style.visibility = _showSpinner ? "visible" : "hidden";
    _showSpinner
      ? spinnerEl.classList.remove("change-kw-spinner-size")
      : spinnerEl.classList.add("change-kw-spinner-size");
    let kwListElem = document.querySelector(".form-keywords-list-selector");
    kwListElem.style.visibility = _showSpinner ? "hidden" : "visible";
    _showSpinner
      ? kwListElem.classList.add("change-kw-spinner-size")
      : kwListElem.classList.remove("change-kw-spinner-size");

    let btnsDiv = document.querySelector(".form-buttons-div-selector");
    let btnsDivChildren = btnsDiv.childNodes;

    for (let i = 0; i < btnsDivChildren.length; i++) {
      const btnEl = btnsDivChildren[i];
      if (btnEl.innerText === "Save Draft") {
        btnEl.disabled = _showSpinner ? true : buttonsStates.disableDraftButton;
      } else {
        btnEl.disabled = _showSpinner ? true : buttonsStates.disableFormButtons;
      }
    }
  };

  const updateDescUI = () => {
    //adds the descrption generated by the gen ai
    // to the description field UI (form data is saved in another method)
    let descriptionEL = document.getElementById(
      `${requestType}-description-input-field`
    );
    let parsedDesc = lastGeneratedDescription.replaceAll("##", "");
    parsedDesc = parsedDesc.trim();
    descriptionEL.value = parsedDesc;
    descriptionEL.classList.add("is-valid");
  };

  const setDataOnUI = (_formData) => {
    // check if form object has data before setting the values on the dom.
    // the request id must be different from zero,
    // because we are loading an implemented request into the form
    if (_formData.requestId <= 0) return;

    // title
    let titleEL = document.getElementById(`${requestType}-title-input-field`);
    titleEL.value = _formData.title.value;
    if (_formData.title.value !== "") {
      titleEL.classList.add("is-valid");
    }

    // url
    let urlEL = document.getElementById(`${requestType}-url-input-field`);
    urlEL.value = _formData.url.value;
    if (_formData.url.value !== "") {
      urlEL.classList.add("is-valid");
    }

    // description
    let descriptionEL = document.getElementById(
      `${requestType}-description-input-field`
    );
    descriptionEL.value = _formData.description.value;
    descriptionEL.classList.add("is-valid");
    if (_formData.description.value !== "") {
      descriptionEL.classList.add("is-valid");
    }

    // location
    let locationEl = document.getElementById(
      `${requestType}-country-select-id`
    );
    if (_formData.countries.value.length > 0) {
      locationEl.classList.add("is-valid");
    }

    // refiner
    let refinerEl = document.getElementById(`${requestType}-refiner-select-id`);
    if (_formData.refinerPages.value.length > 0) {
      refinerEl.classList.add("is-valid");
    }

    // keywords
    let kwEl = document.getElementById(`${requestType}-keyword-input`);
    if (_formData.keywordsObj.keywords.value.length > 0) {
      kwEl.classList.add("is-valid");
    }

    // close loading spinner and show form
    showLoadingSpinner(false);
  };

  const completeFormWithReqData = async (
    requestDataObj,
    showWarningBanner = null,
    itsFromManageMyRequest = null
  ) => {
    const requestIndexData = requestDataObj.bbReqData;
    const implIndexReqData = requestDataObj?.bbData;

    let tempForm = null;

    let reqStatus = null;
    if (
      requestIndexData.cleanrequesttype === "Update" &&
      requestIndexData.cleanstatus === "New" &&
      implIndexReqData.cleanstatus === "Inactive"
    ) {
      reqStatus = "New";
    } else {
      reqStatus = requestIndexData.cleanstatus;
    }

    let bbidData = null;
    let requestIdData = null;
    let urlData = null;
    let titleData = null;
    let descriptionData = null;
    let commentsData = null;
    let countriesData = null;
    let refinerPagesData = null;
    let keywordsData = null;
    let keywordsforreviewData = null;
    let cleancreationdateData = null;
    let lastmodifieddateUTCData = null;
    let lastmodifiedbyData = null;
    let requestedbyData = null;
    let cleanemployeenumberData = null;
    let cleanrequesttypeData = null;
    let yammerthreadidData = null;

    let isOnlyRequest;
    let isUpdateRequest = false;
    let isDraftMode = false;

    if (!requestDataObj?.bbData?.hasOwnProperty("bbid")) {
      isOnlyRequest = true;
    }
    if (requestDataObj?.bbData?.hasOwnProperty("bbid")) {
      isOnlyRequest = false;
    }
    if (requestIndexData.cleanstatus === "Draft") {
      isDraftMode = true;
    }
    if (requestIndexData.cleanrequesttype === "Update") {
      isUpdateRequest = true;
    }

    let showReqData = false;
    if (isOnlyRequest || isUpdateRequest || isDraftMode) {
      showReqData = true;
    }
    if (
      store.state.requestToEditBB?.canEdit &&
      requestIndexData.cleanrequesttype === "Implemented"
    ) {
      showReqData = false;
    }

    // add data from the correct doc
    bbidData = implIndexReqData?.bbid;
    requestIdData = parseInt(requestIndexData.bbrequestid);
    urlData = showReqData
      ? requestIndexData.cleanurl
      : implIndexReqData.cleanurl;
    titleData = showReqData
      ? requestIndexData.cleantitle
      : implIndexReqData.cleantitle;
    descriptionData = showReqData
      ? requestIndexData.cleandescription
      : implIndexReqData.cleandescription;
    commentsData = [...requestIndexData.comments];
    countriesData = showReqData
      ? [...requestIndexData.cleancountry]
      : [...implIndexReqData.cleancountry];
    refinerPagesData = showReqData
      ? [...requestIndexData.aid]
      : [...implIndexReqData.aid];
    keywordsData = showReqData
      ? [...requestIndexData.cleankeywordsraw]
      : [...implIndexReqData.cleankeywordsraw];
    keywordsforreviewData = showReqData
      ? [...requestIndexData.cleankeywordsforreviewraw]
      : [...implIndexReqData.cleankeywordsforreviewraw];
    cleancreationdateData = showReqData
      ? requestIndexData.cleancreationdate
      : implIndexReqData.cleancreationdate;
    lastmodifieddateUTCData = requestIndexData.lastmodifieddateUTC;
    lastmodifiedbyData = showReqData
      ? [...requestIndexData.lastmodifiedby]
      : [{ user: implIndexReqData.createdby }];
    requestedbyData = requestIndexData.requestedby;
    cleanemployeenumberData = parseInt(requestIndexData.cleanemployeenumber);
    cleanrequesttypeData = requestIndexData.cleanrequesttype;
    yammerthreadidData = parseInt(requestIndexData.yammerthreadid);

    //revalidation of url data
    let isUrlValid = null;
    const regex = urlCheckerService.urlRegex;
    if (!urlData.match(regex)) {
      isUrlValid = false;
    } else {
      isUrlValid = true;
    }

    try {
      tempForm = {
        bbid: bbidData,
        requestId: requestIdData,
        url: { value: urlData, isValid: isUrlValid },
        title: { value: titleData, isValid: titleData.length > 0 },
        description: {
          value: descriptionData,
          isValid: descriptionData.length > 0
        },
        commentBox: { value: "", isValid: null },
        comments: commentsData,
        countries: { value: countriesData, isValid: countriesData.length > 0 },
        refinerPages: {
          value: refinerPagesData,
          isValid: refinerPagesData.length > 0
        },
        keywordsObj: {
          keywords: { value: keywordsData, isValid: keywordsData.length > 0 },
          keywordsforreview: { value: keywordsforreviewData, isValid: null }
        },
        cleancreationdate: cleancreationdateData,
        lastmodifieddateUTC: lastmodifieddateUTCData,
        lastmodifiedby: lastmodifiedbyData,
        requestedby: requestedbyData,
        cleanstatus: reqStatus,
        cleanemployeenumber: cleanemployeenumberData,
        cleanrequesttype: cleanrequesttypeData,
        isDraftMode: requestIndexData.cleanstatus === "Draft",
        yammerthreadid: yammerthreadidData,
        qualityscore: 0
      };
    } catch (error) {
      console.log("completeFormWithReqData error : ", error);
    }

    if (showWarningBanner) {
      enableUrlSuggestions(false);
      triggerToast(10, 0, "autoFillMessage");
    }

    if (tempForm !== null) {
      setIsFormWithData(true);
      setBbForm({ ...tempForm });
      setDataOnUI({ ...tempForm });
    }
  };

  // set form to edit or read only mode
  const setFormMode = (
    _request,
    isLoadImplementedFromUrl = null,
    itsFromContentSearcher = null
  ) => {
    let request = null;
    try {
      if (_request.hasOwnProperty("data")) {
        request = { ..._request.data.hits?.hits[0]._source };
      } else {
        request = { ..._request };
      }
    } catch (error) {
      console.log("error on request variable  : ", error);
      showLoadingSpinner(false);
    }

    if (request === null) return;
    let isAdmin = store.state.isAdmin;
    let currentUserPk = localStorage.getItem("searchtools.pk");
    let isOwner =
      parseInt(request.cleanemployeenumber) === parseInt(currentUserPk);
    let itsFromManageMyRequest =
      store.state.requestToEditBB?.itsFromManageMyRequest;

    let currBtnStates = { ...buttonsStates };

    if (
      request.cleanstatus === "Cancelled" ||
      request.cleanstatus === "Not Approved"
    ) {
      if (parseInt(idFromUrl) <= 0) return;
      // the gray pills are true if read only mode is true
      // (only for this case, for the rest, the pills will be green)
      setDisplayGrayPills(true);
      setDisableFormFields(true);
      // use css to hide the buttons div
      try {
        let buttonsDiv = document.querySelector(".form-buttons-div-selector");
        buttonsDiv.style.visibility = "hidden";
        let locRefAndKwEl = document.querySelector(
          ".location-refiner-and-keywords-main-div"
        );
        locRefAndKwEl.style.marginLeft = 0;
      } catch (error) {
        console.log("error on hiding buttons div  : ", error);
        showLoadingSpinner(false);
      }

      try {
        let divHolder = document.querySelector(
          ".kw-div-elements-holder-selector"
        );
        divHolder.classList.remove("show-rocket-button-position");
      } catch (error) {}

      setButtonsStates({
        disableFormButtons: currBtnStates.disableFormButtons,
        disableDraftButton: currBtnStates.disableDraftButton
      });
    } else if (request.cleanstatus === "Implemented") {
      let editMode = false;
      if (isLoadImplementedFromUrl && !store.state.requestToEditBB?.canEdit) {
        editMode = false;
      } else if (
        itsFromManageMyRequest &&
        store.state.requestToEditBB?.canEdit
      ) {
        editMode = true;
      } else if (
        itsFromManageMyRequest &&
        !store.state.requestToEditBB?.canEdit
      ) {
        editMode = false;
      } else if (
        itsFromContentSearcher &&
        store.state.requestToEditBB?.canEdit
      ) {
        editMode = true;
      }

      try {
        let buttonsDiv = document.querySelector(".form-buttons-div-selector");
        buttonsDiv.style.visibility = editMode ? "visible" : "hidden";
        if (!editMode) {
          let locRefAndKwEl = document.querySelector(
            ".location-refiner-and-keywords-main-div"
          );
          locRefAndKwEl.style.marginLeft = 0;
        }
      } catch (error) {
        console.log("error on hiding buttons div 2 : ", error);
        showLoadingSpinner(false);
      }

      setDisableFormFields(!editMode);

      try {
        if (!editMode) {
          let divHolder = document.querySelector(
            ".kw-div-elements-holder-selector"
          );
          divHolder.classList.remove("show-rocket-button-position");
        }
      } catch (error) {}

      setButtonsStates({
        disableFormButtons: currBtnStates.disableFormButtons,
        disableDraftButton: currBtnStates.disableDraftButton
      });
    } else if (request.cleanstatus === "New") {
      let editMode = isOwner || isAdmin;
      try {
        let buttonsDiv = document.querySelector(".form-buttons-div-selector");
        buttonsDiv.style.visibility = editMode ? "visible" : "hidden";
        if (!editMode) {
          let locRefAndKwEl = document.querySelector(
            ".location-refiner-and-keywords-main-div"
          );
          locRefAndKwEl.style.marginLeft = 0;
        }
      } catch (error) {
        console.log("error on hiding buttons div 3 : ", error);
        showLoadingSpinner(false);
      }
      setButtonsStates({
        disableFormButtons: currBtnStates.disableFormButtons,
        disableDraftButton: currBtnStates.disableDraftButton
      });

      try {
        if (!editMode) {
          let divHolder = document.querySelector(
            ".kw-div-elements-holder-selector"
          );
          divHolder.classList.remove("show-rocket-button-position");
        }
      } catch (error) {}

      setDisableFormFields(!editMode);
    }
  };

  const isUrlClean = () => {
    return !bbForm.url.value.includes('"') && !bbForm.url.value.includes("\\");
  };

  const hideFormUI = () => {
    let mainFormEl = document.querySelector(".form-main-div");
    mainFormEl.style.display = "none";
  };

  //will handle the change on the url field, but also when the page loads
  const handleUrlChange = async (
    idFromRedirect = null,
    showWarningBanner = null
  ) => {
    if (idFromRedirect === null) {
      if (bbForm.url.value.trim().length === 0) return;
    }

    let request = null;
    let bbRequest = null;
    let currentOriginalData = { ...originalReqData };

    if (idFromRedirect !== null) {
      request = await bestBetService.GetRequestById(idFromRedirect);
    } else if (isUrlClean()) {
      request = await bestBetService.GetBestBetExact(bbForm.url.value);
    }
    let totalHits = request.data.hits.total.value;
    if (totalHits === 0 && idFromRedirect !== null) {
      hideFormUI();
      showLoadingSpinner(false);
      setNotFoundMessage(true);
    } else {
      let fromRequestIndex = {};
      if (request !== null) {
        const latestRequests = request.data.hits.hits;
        let lastPos = latestRequests.length - 1;
        lastPos = lastPos < 0 ? 0 : lastPos;

        let latestReq = null;

        try {
          latestReq = { ...latestRequests[lastPos]._source };
        } catch (error) {
          console.log("error on getting latest request from url");
        }

        if (latestReq === null) return;

        let isLoadImplementedFromUrl = false;
        if (latestRequests[lastPos]._index.includes("bbsearch")) {
          fromRequestIndex = await bestBetService.GetRequestByURL(
            latestRequests[lastPos]._source.cleanurl
          );

          const reqHitsArr = [...fromRequestIndex.data.hits.hits];

          if (reqHitsArr.length > 0) {
            let lastPos = latestRequests.length - 1;
            lastPos = lastPos < 0 ? 0 : lastPos;
            currentOriginalData.bbReqData = { ...reqHitsArr[lastPos]._source };
            latestReq.comments = reqHitsArr[0]._source.comments;

            latestReq.cleanstatus = reqHitsArr[0]._source.cleanstatus;

            latestReq.cleanrequesttype = reqHitsArr[0]._source.cleanrequesttype;

            latestReq.cleanemployeenumber =
              reqHitsArr[0]._source.cleanemployeenumber;

            latestReq.bbrequestid = reqHitsArr[0]._source.bbrequestid;

            isLoadImplementedFromUrl = true;
          }
        }

        let bbid = latestReq.bbid;
        if (parseInt(bbid) > 0) {
          bbRequest = await bestBetService.GetBestBetByID(bbid);

          currentOriginalData.bbData = {
            ...bbRequest.data.hits.hits[0]._source
          };
        }

        let isFromUrlField =
          bbForm.url.value === latestReq.cleanurl &&
          latestReq.cleanstatus !== "Draft";

        let isIdFromRedirect = idFromUrl === latestRequests[lastPos]._id;

        if (isFromUrlField) {
          if (showWarningBanner) triggerToast(10, 0, "autoFillMessage");
          await setFormMode(request, isLoadImplementedFromUrl);
          // store data for comparison and updates
          await setOriginalReqData(currentOriginalData);
        } else if (isIdFromRedirect) {
          await setFormMode(request, isLoadImplementedFromUrl);
          // store data for comparison and updates
          if (!currentOriginalData.bbReqData?.hasOwnProperty("bbrequestid")) {
            currentOriginalData.bbReqData = { ...latestReq };
          }
          await setOriginalReqData(currentOriginalData);

          if (showWarningBanner) triggerToast(10, 0, "autoFillMessage");
        }
      }
    }
  };

  const loadBestBetToEdit = async (bbid, contentSearcher) => {
    let bestBetQuery = null;
    let bbData = null;
    let lastRequest = null;
    let bbReqQuery = null;
    let bbReqData = {};

    showLoadingSpinner(true);

    if (contentSearcher) {
      bestBetQuery = await bestBetService.GetBestBetByID(bbid);

      if (bestBetQuery.data.hits.hits.length > 1) {
        bbData = bestBetQuery.data.hits.hits.pop();
        bbData = bbData._source;
      } else {
        bbData = bestBetQuery.data.hits.hits[0]._source;
      }

      let mostRecentBBData = await bestBetService.getMostRecentBestBet(
        bbData.bbid
      );

      if (mostRecentBBData.data.hits.hits.length > 0) {
        let currReqId = mostRecentBBData.data.hits.hits[0]._source.bbrequestid;
        bbReqQuery = await bestBetService.GetRequestById(currReqId);
        if (bbReqQuery.data.hits.hits.length > 1) {
          lastRequest = bbReqQuery.data.hits.hits.pop();
          bbReqData = lastRequest._source;
        } else {
          bbReqData = bbReqQuery.data.hits.hits[0]._source;
        }
      }
    } else {
      bbReqQuery = await bestBetService.GetRequestById(bbid);

      if (bbReqQuery.data.hits.hits.length > 1) {
        lastRequest = bbReqQuery.data.hits.hits.pop();
        bbReqData = lastRequest._source;
      } else {
        bbReqData = bbReqQuery.data.hits.hits[0]._source;
      }

      if (
        bbReqData.cleanstatus === "Implemented" ||
        bbReqData.bbrequestid > 0 ||
        bbReqData.bbid > 0
      ) {
        bestBetQuery = await bestBetService.GetBestBetByID(bbReqData.bbid);

        if (bestBetQuery.data.hits.hits.length > 1) {
          bbData = bestBetQuery.data.hits.hits.pop();
          bbData = bbData._source;
        } else {
          try {
            bbData = bestBetQuery.data.hits.hits[0]._source;
          } catch (error) {
            bbData = {};
            showLoadingSpinner(false);
          }
        }
      }
    }

    // store data for comparison and updates
    let currentOriginalData = { ...originalReqData };
    currentOriginalData.bbData = { ...bbData };
    currentOriginalData.bbReqData = { ...bbReqData };
    await setFormMode(bbReqData, true, contentSearcher);
    await setOriginalReqData(currentOriginalData);

    localStorage.removeItem("contentSearcherValues");
  };

  const showCompareTable = () => {
    setCompareTableTrigger(true);
  };

  const closeComparisonTable = () => {
    setCompareTableTrigger(false);
  };

  const checkIfFormIsValid = () => {
    let isTitleValid =
      bbForm.title.isValid === null ? false : bbForm.title.isValid;
    let isUrlValid = bbForm.url.isValid === null ? false : bbForm.url.isValid;
    let isDescValid =
      bbForm.description.isValid === null ? false : bbForm.description.isValid;
    let isLocationValid =
      bbForm.countries.isValid === null ? false : bbForm.countries.isValid;
    let isRefinerValid =
      bbForm.refinerPages.isValid === null
        ? false
        : bbForm.refinerPages.isValid;
    let isKwValid =
      bbForm.keywordsObj.keywords.isValid === null
        ? false
        : bbForm.keywordsObj.keywords.isValid;

    let isFormValid =
      isTitleValid &&
      isUrlValid &&
      isDescValid &&
      isLocationValid &&
      isRefinerValid &&
      isKwValid;

    let drafTitle =
      bbForm.title.value.length > 0 &&
      !bbForm.title.value.includes('"') &&
      !bbForm.title.value.includes("\\");
    let draftUrl =
      bbForm.url.value.length > 0 &&
      !bbForm.url.value.includes('"') &&
      !bbForm.url.value.includes("\\");
    let draftDescription =
      bbForm.description.value.length > 0 &&
      !bbForm.description.value.includes('"') &&
      !bbForm.description.value.includes("\\");

    let enableDraft =
      drafTitle ||
      draftUrl ||
      draftDescription ||
      bbForm.countries.length > 0 ||
      bbForm.refinerPages.length > 0 ||
      bbForm.keywordsObj.keywords.length > 0;

    if (isKwValid) {
      let kwInputRef = document.querySelector("#BB-keyword-input");
      kwInputRef.classList.remove("is-invalid");
      kwInputRef.classList.add("is-valid");
      let kwErrorMsgRef = document.querySelector("#kw-error-feedback-div");
      kwErrorMsgRef.innerHTML = "";
    }

    setButtonsStates({
      disableFormButtons: !isFormValid,
      disableDraftButton: !enableDraft
    });
  };

  // close suggestion list and loads form based on url
  const closeSuggEventListener = (event) => {
    if (event.key === "Escape") {
      enableUrlSuggestions(false);
    }
  };

  const checkToloadFromUrl = (event) => {
    try {
      enableUrlSuggestions(false);
      selectSuggestion(event);
    } catch (error) {}
  };

  const showSuggestions = async () => {
    const request = await store.services.bestBetService.GetBbSuggestions(
      decodeURIComponent(bbForm.url.value)
    );

    const suggestions = request.data.hits.hits;
    setURLSuggestions({ show: true, requests: suggestions });
  };

  const enableUrlSuggestions = (_enableField, _isFirstCase = null) => {
    let urlSuggestionElm = document.querySelector(".url-suggestions-selector");
    if (!parseInt(idFromUrl) > 0) {
      urlSuggestionElm.style.visibility = _enableField ? "visible" : "hidden";
      urlSuggestionElm.style.height = _enableField ? "auto" : 0;
      urlSuggestionElm.style.marginTop = _enableField ? "-12px" : "-0.9em";
    } else if (_isFirstCase !== null) {
      urlSuggestionElm.style.visibility = _enableField ? "visible" : "hidden";
      urlSuggestionElm.style.height = _enableField ? "auto" : 0;
      urlSuggestionElm.style.marginTop = _enableField ? "-12px" : "-0.9em";
    }
  };

  const handleUrlFromSuggestionList = (event) => {
    try {
      // get url from dropdown
      let tempHtmlObj = event.relatedTarget;
      tempHtmlObj.classList.add("test-class1");
      tempHtmlObj.classList.remove("test-class1");

      let usrSelectedUrl = event.target.value;

      if (!usrSelectedUrl.includes("http")) return;

      // change url field css
      let errorMessageDiv = document.getElementById(`BB-url-error-msg-box`);
      errorMessageDiv.textContent = "";
      errorMessageDiv.hidden = true;

      let urlEL = document.getElementById(`${requestType}-url-input-field`);
      urlEL.value = usrSelectedUrl;
      urlEL.classList.remove("is-invalid");
      urlEL.classList.add("is-valid");

      // set url on form data
      let tempForm = { ...bbForm };
      bbForm.url.value = usrSelectedUrl;
      setBbForm({ ...tempForm });
    } catch (error) {
      console.log("error on selectSuggestion ", error);
    }
  };

  const selectSuggestion = async (event) => {
    event.preventDefault();
    setURLSuggestions({ show: false, requests: [] });
    enableUrlSuggestions(false);
    handleUrlFromSuggestionList(event);
    handleUrlChange(null, true).then(() => {});
  };

  const suggestionKeydown = (event) => {
    if (event.key == "Enter" || event.key == " ") {
      selectSuggestion(event);
    }
  };

  const openGenAiModal = async (e, isRocketBtnDisabled) => {
    let descRadioButton = document.getElementById(
      "gen-ai-option-radio-btn-description"
    );
    let keywordsRadioButton = document.getElementById(
      "gen-ai-option-radio-btn-keywords"
    );
    let selectedOption = "";

    if (descRadioButton.checked) {
      selectedOption = "description";
    } else if (keywordsRadioButton.checked) {
      selectedOption = "keywords";
    }

    setSelectedGenAiOption(selectedOption);
    if (!isRocketBtnDisabled) {
      e.preventDefault();
      setShowGenIADescModal(true);
      await getSelectedGenAiData(selectedOption);
    }
  };

  const handleCloseGenAiModal = () => {
    setShowGenIADescModal(false);
  };

  const saveAILogs = async (data) => {
    try {
      const response = await store.services.aiLogsService.SaveAIUsage(data);
      console.log("logs saved ", response);
    } catch (error) {
      console.log("error on saving logs ", error);
    }
  };

  let dateObj = new Date();
  let formattedDate = dateObj.toISOString().replace("T", " ").slice(0, 19);

  const [showGenAiSpinner, setShowGenAiSpinner] = useState(false);

  const getSelectedGenAiData = async (selectedOption) => {
    await setLastGeneratedKeywords([]);

    if (selectedOption === "description") {
      // make loading spinner spin for a while, then show modal with data
      setIsGenAiLoadingData(true);
      let genAiDescObj = await GenAiDescription.GetGenAiDescription(
        bbForm.url.value,
        bbForm.url.isValid,
        urlCheckerService,
        genAIService
      );
      if (genAiDescObj && genAiDescObj.description) {
        const data = {
          eid: localStorage.getItem("searchtools.eid"),
          url: bbForm.url.value,
          parent: "bestbet",
          type: "description",
          result: genAiDescObj.description,
          date: formattedDate
        };
        saveAILogs(data);
      }

      setTimeout(() => {
        setIsGenAiLoadingData(false);
      }, 2000);
    } else {
      // enable loading spinner untill data is ready to be shown to the user
      setIsGenAiLoadingData(true);
      let requestedKeywords = 0;
      let currGreenKw = bbForm.keywordsObj.keywords.value.length;
      let currRedKw = bbForm.keywordsObj.keywordsforreview.value.length;
      let totalCurrKw = currGreenKw + currRedKw;
      requestedKeywords = 20 - totalCurrKw;

      let genAiKwObj = {};
      let validKwList = [];
      let currLocationsSelected = [...bbForm.countries.value];
      let currRefinersSelected = [...bbForm.refinerPages.value];
      if (requestedKeywords !== 0) {
        try {
          genAiKwObj = await GenAiDescription.GetGenAiKeywords(
            bbForm.url.value,
            bbForm.url.isValid,
            urlCheckerService,
            genAIService,
            requestedKeywords
          );

          // only show valid keywords to the user
          for (let i = 0; i < requestedKeywords; i++) {
            if (i < 20) {
              let kw = genAiKwObj.keywords[i];
              let lowerCaseKw = "";
              kw = kw.replaceAll(".", "");
              lowerCaseKw = kw.toLowerCase();
              let validationResult = await BB_Validations.validateKeyword(
                kw,
                currLocationsSelected,
                currRefinersSelected
              );
              if (
                validationResult.isValid &&
                !bbForm.keywordsObj.keywords.value.includes(lowerCaseKw) &&
                !bbForm.keywordsObj.keywords.value.includes(kw)
              ) {
                validKwList.push(kw);
              }
            }
          }

          if (validKwList.length > 0) {
            genAiKwObj.keywords = [...validKwList];
            await setLastGeneratedKeywords(genAiKwObj.keywords);
            setIsGenAiLoadingData(false);
            if (genAiKwObj && genAiKwObj.keywords) {
              const data = {
                eid: localStorage.getItem("searchtools.eid"),
                url: bbForm.url.value,
                parent: "bestbet",
                type: "keyword",
                result: genAiKwObj.keywords,
                date: formattedDate
              };
              saveAILogs(data);
            }
          } else if (validKwList.length <= 0) {
            setIsGenAiLoadingData(false);
            try {
              setTimeout(() => {
                let kwResultInfo =
                  document.getElementById("generated-keywords");
                kwResultInfo.innerText =
                  "We were unable to generate additional keywords for this URL.";
              }, 700);
            } catch (error) {
              console.log("err : ", error);
            }
          }
        } catch (error) {
          setIsGenAiLoadingData(false);
          try {
            setTimeout(() => {
              let kwResultInfo = document.getElementById("generated-keywords");
              kwResultInfo.innerText =
                "Something went wrong, please try again.";
            }, 700);
          } catch (error) {
            console.log("err : ", error);
          }
        }
      }
    }
  };

  // form setup useEffect, hides some elements of the ui and set other for later use
  useEffect(() => {
    try {
      GenAiDescription.showRocketBtn(false);
      if (!store.state.hasOwnProperty("requestToEditBB")) {
        if (parseInt(idFromUrl) === 0 || idFromUrl === undefined) {
          let mainFormEl = document.querySelector(".form-main-div");
          mainFormEl.style.visibility = "hidden";
          GenAiDescription.showRocketBtn(false);
          setTimeout(() => {
            mainFormEl.style.visibility = "visible";
            GenAiDescription.showRocketBtn(true);
          }, 700);
        }
      }

      enableUrlSuggestions(false, false);

      // hide keyword field spinner
      let kwSpinnerEl = document.querySelector(".keywords-loading-spinner");
      // kwSpinnerEl.style.height = "0px";
      kwSpinnerEl.classList.add("change-kw-spinner-size");

      // hide synonyms suggestion list
      let synSuggDiv = document.querySelector(".kw-synonyms-list-selector");
      synSuggDiv.style.visibility = "hidden";
      synSuggDiv.classList.add("change-kw-spinner-size");

      try {
        // close the url suggestion list with Esc key when the list is available
        window.removeEventListener("keydown", closeSuggEventListener, true);
        window.addEventListener("keydown", closeSuggEventListener);
      } catch (error) {}
    } catch (error) {}
  }, []);

  useEffect(() => {
    TooltipManager.instantiateTooltips(requestType);

    setButtonsStates({
      disableFormButtons: buttonsStates.disableFormButtons,
      disableDraftButton: buttonsStates.disableDraftButton
    });

    checkIfFormIsValid();
  }, [bbForm]);

  useEffect(() => {
    // after the data from index has been saved, fill the form
    if (
      originalReqData.bbData?.bbid ||
      originalReqData.bbReqData?.bbrequestid
    ) {
      let currBBid = store.state?.requestToEditBB?.bbid;
      setHeaderInfo({
        showLastModified: idFromUrl === null || idFromUrl === undefined,
        showSecondSection: idFromUrl > 0,
        showInfoHeader: currBBid || originalReqData.bbReqData?.bbrequestid > 0
      });

      let showWarningBanner =
        store.state?.requestToEditBB?.bbid &&
        store.state?.requestToEditBB?.canEdit &&
        store.state?.requestToEditBB?.itsFromManageMyRequest &&
        store.state?.requestToEditBB?.showWarningBanner;
      completeFormWithReqData(originalReqData, showWarningBanner);
    }
  }, [originalReqData]);

  useEffect(() => {
    if (urlSuggestions.requests.length > 0) {
      enableUrlSuggestions(!isFormWithData);
    }
  }, [urlSuggestions]);

  var userInputTimeout;
  useEffect(() => {
    var isCancelled = false;

    if (bbForm.url.value.length === 0) {
      GenAiDescription.enableRocketBtn(false);
      setDisableRocketBtn(true);
      setShowGenAiSpinner(false);
      setShowGenAiWarning(false);
    } else {
      if (disableFormFields) {
        GenAiDescription.enableRocketBtn(false);
        setDisableRocketBtn(true);
        setShowGenAiSpinner(false);
        setShowGenAiWarning(false);
        return;
      }
      try {
        userInputTimeout = setTimeout(async () => {
          if (isUrlClean() && bbForm.url.value !== "") {
            await showSuggestions();
            //get Gen ai description
            GenAiDescription.showRocketBtn(false);
            setShowGenAiSpinner(true);
            try {
              let genAiDescObj = await GenAiDescription.GetGenAiDescription(
                bbForm.url.value,
                bbForm.url.isValid,
                urlCheckerService,
                genAIService
              );

              //Check if call was cancelled(the user modified the input)
              if (isCancelled || genAiDescObj === undefined) {
                setShowGenAiSpinner(false);
                GenAiDescription.showRocketBtn(true);
                setDisableRocketBtn(true);
                setShowGenAiWarning(false);
                return;
              }
              if (genAiDescObj.hasOwnProperty("showRocketBtn")) {
                GenAiDescription.showRocketBtn(true);
                setShowGenAiSpinner(false);
                if (
                  genAiDescObj.description !== "" &&
                  !genAiDescObj.description.includes(
                    "There is not enough information about this site"
                  )
                ) {
                  GenAiDescription.enableRocketBtn(
                    genAiDescObj.enableRocketBtn
                  );
                  setDisableRocketBtn(!genAiDescObj.enableRocketBtn);
                  setShowGenAiWarning(false);
                } else {
                  setShowGenAiWarning(true);
                  GenAiDescription.enableRocketBtn(false);
                  setDisableRocketBtn(true);
                }
              }
              setLastGeneratedDescription(genAiDescObj.description);
            } catch (error) {
              setShowGenAiWarning(true);
              setShowGenAiSpinner(false);
              GenAiDescription.showRocketBtn(true);
              GenAiDescription.enableRocketBtn(false);
              setDisableRocketBtn(true);
            }
          }
        }, 800);

        return () => {
          clearTimeout(userInputTimeout);
          isCancelled = true;
        };
      } catch (error) {}
    }
  }, [bbForm.url.value]);

  // use effect for when the user loads request via browser url, not form url
  useEffect(() => {
    if (parseInt(idFromUrl) > 0) {
      showLoadingSpinner(true);
      handleUrlChange(idFromUrl, false).then(() => {});
    }
  }, [idFromUrl]);

  // useEffect for when the user requests and update from manage my requests
  useEffect(() => {
    if (store.state?.requestToEditBB?.canEdit) {
      let isContentSearcher = store.state?.requestToEditBB?.contentSearcher;
      loadBestBetToEdit(store.state?.requestToEditBB?.bbid, isContentSearcher);
    }

    try {
      // loading requests from content searcher in a new tab
      let contentSearcherValues = localStorage.getItem("contentSearcherValues");

      if (contentSearcherValues !== null && contentSearcherValues !== "") {
        let contentSearcherData = JSON.parse(contentSearcherValues);

        store.state.requestToEditBB = {
          canEdit: contentSearcherData.canEdit,
          bbid: contentSearcherData.bbid,
          contentSearcher: contentSearcherData.contentSearcher,
          itsFromManageMyRequest: contentSearcherData.itsFromManageMyRequest
        };

        loadBestBetToEdit(contentSearcherData.bbid, true);
      }
    } catch (error) {}
  }, []);

  // all validations classes should have a method with the same name,
  // parameters and return values for each of the form fields (with certain exceptions).
  return (
    <div>
      <FormSpinners />
      <ToastWithClose text={autoFillMessage} toastKey="autoFillMessage" />
      {notFoundMessage && <NotFoundRequest />}
      <form className={`form-main-div form-bb`} role="form" noValidate>
        <FormInfoHeader
          reqType={requestType}
          formName={"Best Bet"}
          requestId={bbForm.requestId}
          lastRequestedBy={bbForm.lastmodifiedby}
          formRequestType={bbForm.cleanrequesttype}
          formStatus={bbForm.cleanstatus}
          cleanCreationdate={bbForm.cleancreationdate}
          showLastModified={headerInfo.showLastModified}
          showSecondSection={headerInfo.showSecondSection}
          showInfoHeader={headerInfo.showInfoHeader}
        />
        {/* inputType value must be equal 
        to form object property (formData.title for example)  */}
        <TitleField
          reqType={requestType}
          inputType={"title"}
          labelText={"Title"}
          placeholderText={"Enter site title associated with specified URL."}
          ariaLabelText={
            "Title Info. For better Search results relevancy for this requested Best Bet, enter the site title as it appears when navigating to the entered URL."
          }
          tooltipText={
            "For better Search results relevancy for this requested Best Bet, enter the site title as it appears when navigating to the entered URL."
          }
          isRequired={true}
          isDisabledProp={disableFormFields}
          validateField={BB_Validations.validateTitle}
          updateFormState={updateFormState}
        />
        <UrlField
          reqType={requestType}
          inputType={"url"}
          labelText={"URL"}
          placeholderText={"Enter a valid URL."}
          ariaLabelText={
            "URL Info. Enter a valid URL, for example, https://SampleBestBetURL.com, to promote specific site content as a Best Bet within Enterprise Search."
          }
          tooltipText={
            "Enter a valid URL (e.g., https://SampleBestBetURL.com) to promote specific site content as a Best Bet within Enterprise Search."
          }
          isRequired={true}
          isDisabledProp={disableFormFields}
          urlCheckerService={urlCheckerService}
          validateField={BB_Validations.validateURL}
          updateFormState={updateFormState}
          checkToloadFromUrl={checkToloadFromUrl}
          openGenAiModal={openGenAiModal}
          showGenAiSpinner={showGenAiSpinner}
          disableGenIaBtn={disableRocketBtn}
          showAiWarning={showGenAiWarning}
          hideGenAiFeature={disableFormFields}
          setSelectedGenAiOption={setSelectedGenAiOption}
          showGenAiOptions={showGenAiOptions}
          setShowGenAiOptions={setShowGenAiOptions}
          currFormData={bbForm}
          cssTheme={theme}
        />
        <UrlSuggestions
          urlSuggestions={urlSuggestions}
          selectSuggestion={selectSuggestion}
          suggestionKeydown={suggestionKeydown}
        />
        <DescriptionOrCommentField
          reqType={requestType}
          inputType={"description"}
          labelText={"Description"}
          placeholderText={
            "Enter a brief description of site content that will attract users to click this Best Bet."
          }
          ariaLabelText={
            "Description input. Enter a brief description of site content that will attract users to click this Best Bet."
          }
          tooltipText={`The description must be ${process.env.REACT_APP_BB_DESCRIPTION_LIMIT} characters or less.`}
          isRequired={true}
          isDisabledProp={disableFormFields}
          validateField={BB_Validations.validateDescription}
          updateFormState={updateFormState}
          textAreaValueProp={bbForm.description.value}
        />
        {/* fields is the array containing the name of the form object related to each input.
         its like the field inputType on other components,
          it points to the field wich then will be updated by the updateFormState method */}
        <LocationRefinerAndKeywords
          reqType={requestType}
          formName={"Best Bet"}
          fields={locRefKwfields}
          formValidations={BB_Validations}
          updateFormState={updateFormState}
          currFormData={bbForm}
          idFromUrl={idFromUrl}
          readOnlyMode={disableFormFields}
          displayGrayPills={displayGrayPills}
          tooltipsObj={locRefAndKwTooltipObj}
          showKwLoadingSpinner={showKwLoadingSpinner}
          synonymsServiceRef={synonymsServiceRef}
        />
        <DescriptionOrCommentField
          reqType={requestType}
          inputType={"commentBox"}
          labelText={"Comments"}
          placeholderText={
            "If needed, provide additional comments/details to be considered as part of this Best Bet request."
          }
          ariaLabelText={
            "Comments input. If needed, provide additional comments, details, to be considered as part of this Best Bet request."
          }
          tooltipText={
            "This comment field can also be used to provide additional information requested by the Search CoE team as part of the Best Bet request process."
          }
          isRequired={false}
          isDisabledProp={disableFormFields}
          validateField={BB_Validations.validateComment}
          updateFormState={updateFormState}
          textAreaValueProp={undefined}
        />
        {parseInt(idFromUrl) > 0 && isFormWithData ? (
          <>
            {bbForm.comments?.map((comment, idx) => (
              <CommentHistory
                key={idx}
                user={comment.user}
                date={comment.dateUTC}
                comment={comment.comment}
                commentCSS={"comment"}
              />
            ))}
          </>
        ) : (
          <></>
        )}
        {/* this is a wrapper for the buttons, 
        the buttons now will be ui elements, and they will have attached the methods references,
        but the methods and how each forms saves the data is handled by the wrapper, 
        the FormButtons component is imported inside the wrapper,
         so each form will have a unique wrapper, 
         but all of them will use the FormButtons component   */}
        <BBButtonsWrapper
          reqType={requestType}
          formName={"Best Bet"}
          idFromUrl={idFromUrl}
          disableFormButtons={buttonsStates.disableFormButtons}
          disableDraftButton={buttonsStates.disableDraftButton}
          cssTheme={theme}
          appStore={store}
          currFormData={bbForm}
          dataFromIndex={originalReqData.bbReqData}
          showPreview={showPreview}
          callCompareTable={showCompareTable}
          showLoadingSpinner={showLoadingSpinner}
          setModalProps={(msg, onClose) => {
            setFormImplementedModal({
              show: true,
              msg: msg,
              onClose: onClose
            });
          }}
        />
        {showPreviewTrigger && (
          <Preview
            preview={
              <BBPreview
                title={bbForm.title.value}
                url={bbForm.url.value}
                description={bbForm.description.value}
              />
            }
            closePreview={closeBbPreview}
          />
        )}
        {/* -------------------------------------- modals section */}
        {compareTableTrigger ? (
          <ModalCustom
            darkMode={theme === "dark"}
            onClick={closeComparisonTable}
            onClose={closeComparisonTable}
            modal_msg={
              <CompareTable
                originalReqData={originalReqData}
                currentData={bbForm}
                closeComparisonTable={closeComparisonTable}
              />
            }
            btn_1_class={
              theme === "dark"
                ? "btn btn-dark-mode-purple"
                : "btn btn-light-mode-purple"
            }
            modalSize={"modal-xl"}
            noHeader={true}
          />
        ) : (
          <></>
        )}
        {formImplementedModal.show ? (
          <ModalCustom
            darkMode={theme === "dark"}
            onClick={closeFormImplementedModal}
            onClose={closeFormImplementedModal}
            modal_msg={formImplementedModal.msg}
            btn_1_class={
              theme === "dark"
                ? "btn btn-dark-mode-purple"
                : "btn btn-light-mode-purple"
            }
            modalSize=""
            noHeader={true}
          />
        ) : (
          <></>
        )}
        {showGenIADescModal && (
          <GenAiModal
            closeModal={handleCloseGenAiModal}
            selectedGenAiOption={selectedGenAiOption}
            lastGeneratedDescription={lastGeneratedDescription}
            lastGeneratedKeywords={lastGeneratedKeywords}
            replaceDescription={updateFormState}
            addNewKeywords={updateFormState}
            currFormData={bbForm}
            updateDescUI={updateDescUI}
            url={bbForm.url.value}
            BB_Validations={BB_Validations}
            validationType={"BB_Validations"}
            isGenAiLoadingData={isGenAiLoadingData}
            changeIsGenAiLoadingData={setIsGenAiLoadingData}
            setShowGenAiOptions={setShowGenAiOptions}
            requestType={requestType}
          />
        )}
      </form>
    </div>
  );
};

export default BBForm;
