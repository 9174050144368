

import { useState, useEffect } from "react";
import FieldLabelAndIcon from "../../../FormUI/FormFields/FieldLabelAndIcon";
import ModalCustom from "../../../../Modal/ModalCustom";
import TCLineItems from "./TCLineItems";

const LineItemsHolder = ({
    requestType,
    updateFormState,
    currFormData,
    cssTheme,
    isSupportContentChecked,
    setSupportContentChecked,
    isSitesNewsChecked,
    setSitesNewsChecked,
    isLearningMaterialsChecked,
    setLearningMaterialsChecked,
    isSalesMaterialChecked,
    setSalesMaterialChecked,
    isAccenturePoliciesChecked,
    setAccenturePoliciesChecked,
    isInternalResourcesChecked,
    setInternalResourcesChecked,
    isExternalResourcesChecked,
    setExternalResourcesChecked,
    inputStates,
    updateLineItemState,
    setNewLineItem,
    setNewLineItemState,
    isFormReadOnly,
    formContainsData
}) =>{

    
    const [toggleMainButton, setToggleMainButton] = useState(false);

    const inputID = "TC-line-items-holder-main-button";
    const errorMsgBoxID = `TC-line-items-holder-main-div-error-msg-box`;

    const primaryBtn =
    cssTheme === "dark" ? "dark-mode-purple" : "light-mode-purple";



    const openLineItemsModal = (e)=>{
        e.preventDefault();
        setToggleMainButton(true);
    };
    
    const closeLineItemsModal = ()=>{
        setToggleMainButton(false);
    }



    //add here the line items component that will be shown on the modal
    const lineItemsBody = (
        <div className="line-items-modal-body-div" >
            <TCLineItems
              reqType={requestType}
              title={"Support Content"}
              isDiamond={false}
              isChecked={isSupportContentChecked}
              setIsChecked={setSupportContentChecked}
              lineItem={currFormData.supportContent}
              setLineItem={updateFormState}
              lineItemId={"support-content"}
              field="supportContent"
              state={inputStates.supportContent}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
            <TCLineItems
              reqType={requestType}
              title={"Sites & News"}
              isDiamond={false}
              isChecked={isSitesNewsChecked}
              setIsChecked={setSitesNewsChecked}
              lineItem={currFormData.sitesNews}
              setLineItem={updateFormState}
              lineItemId={"sites-news"}
              field="sitesNews"
              state={inputStates.sitesNews}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
            <TCLineItems
              reqType={requestType}
              title={"Learning Materials"}
              isDiamond={false}
              isChecked={isLearningMaterialsChecked}
              setIsChecked={setLearningMaterialsChecked}
              lineItem={currFormData.learningMaterials}
              setLineItem={updateFormState}
              lineItemId={"learning-materials"}
              field="learningMaterials"
              state={inputStates.learningMaterials}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
            <TCLineItems
              reqType={requestType}
              title={"Sales Material"}
              isDiamond={false}
              isChecked={isSalesMaterialChecked}
              setIsChecked={setSalesMaterialChecked}
              lineItem={currFormData.salesMaterial}
              setLineItem={updateFormState}
              lineItemId={"sales-materials"}
              field="salesMaterial"
              state={inputStates.salesMaterial}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
            <TCLineItems
              reqType={requestType}
              title={"Accenture Policies"}
              isDiamond={false}
              isChecked={isAccenturePoliciesChecked}
              setIsChecked={setAccenturePoliciesChecked}
              lineItem={currFormData.accenturePolicies}
              setLineItem={updateFormState}
              lineItemId={"accenture-policies"}
              field="accenturePolicies"
              state={inputStates.accenturePolicies}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
            <TCLineItems
              reqType={requestType}
              title={"Internal Resources"}
              isDiamond={true}
              isChecked={isInternalResourcesChecked}
              setIsChecked={setInternalResourcesChecked}
              lineItem={currFormData.internalResources}
              setLineItem={updateFormState}
              lineItemId={"internal-resources"}
              field="internalResources"
              state={inputStates.internalResources}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
            <TCLineItems
              reqType={requestType}
              title={"External Resources"}
              isDiamond={true}
              isChecked={isExternalResourcesChecked}
              setIsChecked={setExternalResourcesChecked}
              lineItem={currFormData.externalResources}
              setLineItem={updateFormState}
              lineItemId={"external-resources"}
              field="externalResources"
              state={inputStates.externalResources}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
        </div>
    );



    return(
        <div id={`TC-line-items-holder-main-div`} className="TC-line-items-main-div-class">
            <FieldLabelAndIcon
                reqType={"TC"}
                inputType={"TC-line-items-main-button"}
                inputID={`${inputID}-label`}
                isRequired={true}
                labelText={"Line Items"}
                tooltipText={"Enter at least one line item containing title and URL."}
            />
            <button 
                id={`${inputID}`} 
                className={`btn btn-primary ${primaryBtn} TC-line-items-main-btn `}
                onClick={(e) => {
                    openLineItemsModal(e);
                }}
                disabled={isFormReadOnly}
            >
                Click here to add Topic Card Line Items →
            </button>
            <div id={errorMsgBoxID} className="invalid-field-div"></div>
            {toggleMainButton && 
                <ModalCustom
                xClose={true}
                onClose={closeLineItemsModal}
                modal_msg={lineItemsBody}
                modal_title="Select the section for your topic card and add a link to
                each of them"
                firstOptText="OK"
                secondOption={false}
                modalSize={"description-modal "}
                firstBtnAction={closeLineItemsModal}
                noFooter={false}
                zindex={11}
                firstOpAriaLabel="Close the line items menu"
                btn_1_class={"modal-button-second line-items-btn-ok-class"} // add new class to square button
                modalBorders={"line-items-modal-extra-class"}
                />
            }
        </div>
    );

}


export default LineItemsHolder;