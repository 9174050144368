import { Tooltip } from "bootstrap";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../ThemeContext";
import "./Searcher.css";
import StoreContext from "../../store/storecontext";
const SearcherCard = ({ data }) => {
  let cardDescription = "";
  if (data.description !== undefined) {
    cardDescription = data.description;
  } else if (data.hasOwnProperty("steps") && data.steps !== undefined) {
    cardDescription = `1 - ${data.steps[0]}`;
  }
  const [theme] = useContext(ThemeContext);
  const tooltipClass = theme === "dark" ? "bb-tooltip-dark" : "";
  const [isCollapsed, setIsCollapsed] = useState(data.keywords.length > 10);
  const store = useContext(StoreContext);

  const handleLinkClick = () => {

    if (data.reqType === "BB") {
      store.state.requestToEditBB = {
        canEdit: true,
        bbid: data.id,
        contentSearcher: true,
        itsFromManageMyRequest: false
      };

      let storeJson = {
        canEdit: true,
        bbid: data.id,
        contentSearcher: true,
        itsFromManageMyRequest: false
      };

      let strJson = JSON.stringify(storeJson);
      localStorage.setItem("contentSearcherValues", strJson);
    }
    if (data.reqType === "SY") {
      store.state.requestToEditSYN = {
        canEdit: true,
        synonym_term: data.title,
        contentSearcher: true,
        itsFromManageMyRequest: false
      };

      let storeJson = {
        canEdit: true,
        synonym_term: data.title,
        contentSearcher: true,
        itsFromManageMyRequest: false
      };

      let strJson = JSON.stringify(storeJson);
      localStorage.setItem("contentSearcherValuesSy", strJson);
    }
  };

  const deleteOldTooltips = () => {
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };
  const initializeTitleTooltip = (event) => {
    deleteOldTooltips();

    new Tooltip(event.target, {
      trigger: "hover focus",
      customClass: tooltipClass
    });
  };

  let cardIcon = "";

  if (data.reqType === "BB") {
    cardIcon = "bb-icon";
  } else if (data.reqType === "TC") {
    cardIcon = "tc-icon";
  } else if (data.reqType === "HT") {
    cardIcon = "ht-icon";
  } else if (data.reqType === "SY") {
    cardIcon = "sy-icon";
  } else if (data.reqType === "CTC") {
    cardIcon = "ctc-icon";
  }

  let arrowDark = theme === "dark" ? "darkMode" : "";
  const expandArrows = (
    <div className="searcher-arrows-flex">
      <span alt={"expand"} className={arrowDark + " arrow arrow-top"}></span>
      <span alt={"expand"} className={arrowDark + " arrow arrow-bottom"}></span>
    </div>
  );

  const collapseArrows = (
    <div className="searcher-arrows-flex">
      <span
        alt={"collapse"}
        className={arrowDark + " arrow arrow-bottom collapse-icon-position"}
      ></span>
      <span
        alt={"collapse"}
        className={arrowDark + " arrow arrow-top collapse-icon-position"}
      ></span>
    </div>
  );

  return (
    <div
      id={data.id}
      className="card searcher-card"
      data-testid="searcher-card"
    >
      <div className="card-header">
        <div className={`card-type-icon ${cardIcon}`}>
          <span
            className="reqIcon"
            tabIndex="0"
            data-bs-toggle={"icon-tooltip-" + data.id}
            data-bs-placement="top"
            data-bs-title={data.tooltip}
            data-testid="searcher-card-icon"
            onMouseEnter={initializeTitleTooltip}
          >
            {data.reqType}
          </span>
        </div>
      </div>
      <div className="card-body">
        <div className="card-title-tooltip-div"></div>
        <Link
          id={data.id}
          className="card-title"
          data-testid="card-title"
          to={data.requestLink}
          data-bs-toggle={"title-tooltip-" + data.id}
          data-bs-placement="top"
          data-bs-title={data.titleTooltip}
          onClick={handleLinkClick}
          onMouseEnter={initializeTitleTooltip}
          // target="_blank"
          // rel="noopener noreferrer"
        >
          {data.title}
        </Link>

        <p
          id="card-url"
          className="card-url text-mute mb-2"
          data-testid="card-url"
        >
          {data.url}
        </p>
        <p
          id="card-description"
          className="card-description"
          data-testid="card-description"
        >
          {cardDescription}
        </p>
        {data.keywords.map((kw, index) => {
          if (index < 10) {
            return (
              <button
                id={"keyword" + index}
                className="btn card-keyword mt-1 btn-success"
                data-testid="card-keywords"
                key={kw + index}
              >
                {kw}
              </button>
            );
          } else {
            return isCollapsed ? null : (
              <button
                id={"keyword" + index}
                className="btn card-keyword mt-1 btn-success"
                data-testid="card-keywords"
                key={kw + index}
              >
                {kw}
              </button>
            );
          }
        })}
        {data.keywords.length > 10 && (
          <button
            className="btn btn-secondary expand-collapse mt-1"
            type="button"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? expandArrows : collapseArrows}
          </button>
        )}
      </div>
    </div>
  );
};

export default SearcherCard;
