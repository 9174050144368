import "./CommentHistory.css";
import { formatDate } from "../../../FormatDate/DateFormat";

const CommentHistory = ({ user, date, comment, commentCSS }) => {
  return (
    <div className="comment-tracker comment-tracker-adjust-max-width">
      <div className={`card ${commentCSS}`} data-testid="comment-history">
        <div className={`card-type-icon`}>
          <span
            className="ch-rqst-cmt-user left"
            tabIndex="0"
            data-testid="comment-user"
          >
            {user}
          </span>
          <span
            id="date"
            className="ch-rqst-cmt-date left"
            data-testid="comment-date"
          >
            {formatDate(date)}
          </span>
        </div>
        <p
          id="comment"
          className="ch-rqst-cmt-comment"
          data-testid="comment-text"
        >
          {comment}
        </p>
      </div>
    </div>
  );
};
export default CommentHistory;
