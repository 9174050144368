import { useState } from "react";
import ModalCustom from "../../../Modal/ModalCustom";
import Toast from "../../../Toast/Toast";
import FieldLabelAndIcon from "./FieldLabelAndIcon";
import services from "../../../../store/services";
import {
  EidSuggestion,
  selectSuggestionResult
} from "../EidSuggestion/EidSuggestion";
import { copyToClipboard } from "../../../CopyToClipboard/CopyToClipboard";
import { triggerToast } from "../../../Toast/TriggerToast";

const ContactOrDelegateField = ({
  reqType,
  inputType,
  labelText,
  isRequired,
  updateFormState,
  readOnlyMode,
  formValidations,
  currFormData,
  displayGrayPills,
  placeholderText,
  ariaLabelText,
  tooltipText
}) => {
  const [cssState, setCssState] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [eidSearchTrigger, setEidSearchTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const urlCheckerService = services.urlCheckerService;
  const regex = urlCheckerService.textRegex;

  const InputElID = `${reqType}-${inputType}`;

  const isDarkMode = false;

  const formPillsStyle = "form-pills-style";
  const formPillsBtnFontWeight = "form-pills-btn-font-weigth";

  const changeCssForInput = (validationStatus) => {
    try {
      let userInputEl = document.getElementById(InputElID);
      if (validationStatus) {
        userInputEl.classList.remove("is-invalid");
        userInputEl.classList.add("is-valid");
      } else if (validationStatus === false) {
        userInputEl.classList.remove("is-valid");
        userInputEl.classList.add("is-invalid");
      } else if (validationStatus === null) {
        userInputEl.classList.remove("is-valid");
        userInputEl.classList.remove("is-invalid");
      }
    } catch (error) {}
  };

  const onChangeDelegate = () => {
    setEidSearchTrigger(!eidSearchTrigger);
  };

  const getContactsData = async (eidToAdd) => {
    let newContact;
    if (inputType === "contact-experts-input") {
      let contactExpData = await services.searchToolsService.GetEmployeesByEID(
        eidToAdd,
        true
      );

      contactExpData = contactExpData.data.hits.hits[0]._source;

      newContact = {
        eid: contactExpData.enterpriseid,
        fullname: `${contactExpData.firstname} ${contactExpData.lastname}`,
        role: contactExpData.roledescr || "",
        url: contactExpData.workdayurl
      };

      let newGetInTouchArr = [];
      currFormData.getInTouch.value.map((el) => {
        newGetInTouchArr.push(el);
      });
      newGetInTouchArr.push(newContact);

      return [...newGetInTouchArr];
    } else {
      let delegateUserID = await services.yammerService.GetUserIdYammer(
        eidToAdd
      );
      delegateUserID = delegateUserID.data;
      newContact = {
        eid: eidToAdd,
        userid: delegateUserID
      };

      let newDelegateArr = [];
      currFormData.delegates.value.map((el) => {
        newDelegateArr.push(el);
      });
      newDelegateArr.push(newContact);

      return [...newDelegateArr];
    }
  };

  const validateContactOrDelegate = async (eidToAdd, selectedEidList) => {
    let isInputValid = false;

    try {
      isInputValid = await formValidations.validateContactOrDelegate(
        eidToAdd,
        inputType,
        selectedEidList
      );
      return isInputValid;
    } catch (error) {
      console.log("Error in validateContactOrDelegate", error);
    }
  };

  const addContactOrDelegate = async (event, eidFromSuggestion = null) => {
    if (event.key === "Tab" || event.code === "Tab") return;

    let eidToAdd = "";

    let inputHtmlEl = document.getElementById(InputElID);

    if (eidFromSuggestion !== null) {
      eidToAdd = eidFromSuggestion;
    } else {
      eidToAdd = inputHtmlEl.value;
    }

    if (eidToAdd !== "" && eidToAdd.length >= 3) {
      await setIsLoading(true);
    }

    inputHtmlEl.value = "";
    setEidSearchTrigger(!eidSearchTrigger);

    const allowedEvents = event.key === "Enter" || event.type === "click";

    if (allowedEvents && eidToAdd !== "") {
      if (eidToAdd.match(regex)) {
        setModalMsg('Please do not include the special characters \\ and "');
        setShowModal(true);
        await setIsLoading(false);
      } else {
        let isInputValid = false;

        let selectedEidList = [];

        if (inputType === "contact-experts-input") {
          for (let i = 0; i < currFormData.getInTouch.value.length; i++) {
            const contactExp = currFormData.getInTouch.value[i];
            selectedEidList.push(contactExp.eid);
          }
        } else if (inputType === "delegates-input") {
          for (let j = 0; j < currFormData.delegates.value.length; j++) {
            const delegate = currFormData.delegates.value[j];
            selectedEidList.push(delegate.eid);
          }
        }

        isInputValid = await validateContactOrDelegate(
          eidToAdd,
          selectedEidList
        );

        if (!isInputValid.isValid) {
          setModalMsg(isInputValid.errorMsg);
          setShowModal(true);
          if (selectedEidList.length > 0) {
            changeCssForInput(true);
          }
        } else {
          let newValuesArr = await getContactsData(eidToAdd);
          let updateField =
            inputType === "contact-experts-input" ? "getInTouch" : "delegates";
          updateFormState(updateField, [...newValuesArr], true);
          changeCssForInput(true);
        }

        await setIsLoading(false);
      }
    }
  };

  const removeContactOrDelegate = (event) => {
    event.preventDefault();

    let updateField =
      inputType === "contact-experts-input" ? "getInTouch" : "delegates";
    let eidToRemove = event.target.value;
    let finalList = [];

    if (inputType === "contact-experts-input") {
      finalList = currFormData.getInTouch.value.filter((el) => {
        if (el.eid !== eidToRemove) {
          return el;
        }
      });
    } else if (inputType === "delegates-input") {
      finalList = currFormData.delegates.value.filter((el) => {
        if (el.eid !== eidToRemove) {
          return el;
        }
      });
    }

    let validState = finalList.length > 0;
    if (!validState) {
      changeCssForInput(null);
    }

    updateFormState(updateField, [...finalList], true);
  };

  const checkInputAction = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      addContactOrDelegate(event);
    } else {
      let elemId = event.target.id;
      selectSuggestionResult(event, elemId);
    }
  };

  const clipboardHandler = (event) => {
    copyToClipboard(event);
    triggerToast(1000, 0, "copyToClipboard");
  };
  const renderPillList = () => {
    // use the form data values to render the list
    // this is to make the use of the parent component.
    // instead of depending on this component state

    let currentEidList = [];
    let pillDivId = "";

    if (inputType === "contact-experts-input") {
      pillDivId = "contact-expert";
      for (let i = 0; i < currFormData.getInTouch.value.length; i++) {
        const contactExp = currFormData.getInTouch.value[i];
        currentEidList.push(contactExp.eid);
      }
    } else if (inputType === "delegates-input") {
      pillDivId = "delegate";
      for (let j = 0; j < currFormData.delegates.value.length; j++) {
        const delegate = currFormData.delegates.value[j];
        currentEidList.push(delegate.eid);
      }
    }

    let allbtnList = [];

    for (let i = 0; i < currentEidList.length; i++) {
      const opt = currentEidList[i];
      let idStatusClass = " green-kw-class ";
      let btnStatusClass = displayGrayPills
        ? " btn-gray-class "
        : " btn-success ";

      const nBtn = (
        <div
          id={`${pillDivId}-on-list`}
          data-testid={`testid-${pillDivId}-on-list-div`}
          aria-label={`${pillDivId}-on-list`}
          key={opt + "_div"}
          className={"btn-group " + formPillsStyle}
          role="group"
        >
          <button
            aria-label={`${pillDivId}-on-list ${opt}`}
            data-testid={`testid-${pillDivId}-on-list-pill`}
            key={opt + "_btn_opt_key"}
            className={
              "btn " + btnStatusClass + formPillsBtnFontWeight + idStatusClass
            }
            type="button"
            onClick={clipboardHandler}
          >
            {opt}
          </button>
          {readOnlyMode ? (
            <></>
          ) : (
            <button
              id={opt + "_remove_btn"}
              key={opt + "_remove_btn_key"}
              value={opt}
              className={"btn " + btnStatusClass + formPillsBtnFontWeight}
              type="button"
              aria-label={"remove " + opt + " from list"}
              onClick={removeContactOrDelegate}
            >
              x
            </button>
          )}
        </div>
      );
      allbtnList.push(nBtn);
    }

    return allbtnList;
  };

  const onClickModalHandler = () => {
    setShowModal(false);
  };

  const plusButton = (
    <div
      onClick={addContactOrDelegate}
      onKeyDown={addContactOrDelegate}
      data-testid={`bb-plus-btn`}
      className={"plus-icon-css"}
      data-bs-toggle="plus-icon-tooltip"
      data-bs-placement="top"
      data-bs-title={`Add ${labelText}`}
    >
      <svg
        className={`contact-or-del-add-btn`}
        viewBox="0 0 16 16"
        width={"1.4em"}
        height={"1.4em"}
        role="img"
        alt="icon"
        data-testid={`testid-${reqType}-contact-or-del-plus-icon`}
        id="plus-icon"
        aria-label={`Add ${labelText}`}
        value=""
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginBottom: "2px" }}
        tabIndex={0}
      >
        <g>
          <path
            fillRule="evenodd"
            d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
          ></path>
          <path
            fillRule="evenodd"
            d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
          ></path>
          <path
            fillRule="evenodd"
            d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
          ></path>
        </g>
      </svg>
    </div>
  );

  return (
    <div
      className={
        reqType === "TC" && inputType === "delegates-input"
          ? `${reqType}-contact-and-del-field-extra-margin-2`
          : `${reqType}-contact-and-del-field-extra-margin`
      }
    >
      {showModal ? (
        <ModalCustom
          onClick={onClickModalHandler}
          onClose={onClickModalHandler}
          modal_title={"Info"}
          modal_msg={modalMsg}
          secondOption={false}
          btn_1_class={
            isDarkMode
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
        />
      ) : (
        <div></div>
      )}
      <FieldLabelAndIcon
        reqType={reqType}
        inputType={inputType}
        inputID={`${InputElID}-label`}
        isRequired={isRequired}
        labelText={labelText}
        tooltipText={tooltipText}
      />
      <div className={"contact-or-del-input-and-button-div"}>
        <input
          id={InputElID}
          data-testid={`testid-${InputElID}`}
          type="text"
          className={"form-control " + cssState}
          placeholder={`${placeholderText}`}
          aria-label={`${ariaLabelText}`}
          onKeyDown={checkInputAction}
          onChange={onChangeDelegate}
          disabled={readOnlyMode}
        />
        {readOnlyMode ? <></> : plusButton}
      </div>
      {isLoading && (
        <div className="d-flex contact-or-del-loading-spinner">
          <div
            tabIndex={-1}
            className="spinner-border text-light-mode-purple"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <EidSuggestion
        inputID={InputElID}
        eidSearchTrigger={eidSearchTrigger}
        searchToolsService={services.searchToolsService} //if it doesn't work use store.services and import store
        addUserMethod={addContactOrDelegate}
      />
      <div
        id={`${reqType}-selected-contact-or-del-`}
        data-testid={`testid-${reqType}-selected-contact-or-del-`}
        className={
          "selected-contact-or-del-div form-contact-or-del-list-selector"
        }
        tabIndex={0}
      >
        {!isLoading && renderPillList()}
      </div>
      <Toast
        toastText="EID copied to the clipboard!"
        toastKey="copyToClipboard"
      />
    </div>
  );
};

export default ContactOrDelegateField;
