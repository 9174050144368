import { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../ThemeContext";
import AddSynonymsField from "./FormComponents/AddSynonymsField";
import AddTermField from "./FormComponents/AddTermField";
import SynonymsButtons from "./FormComponents/SynonymsButtons";
import SynonymsPills from "./FormComponents/SynonymsPills";
import SynonymsValidations from "../../../validationClasses/SynonymsValidations";
import { Tooltip } from "bootstrap";
import ModalCustom from "../../Modal/ModalCustom";
import "./SynonymsForm.css";
import StoreContext from "../../../store/storecontext";
import Spinner from "../../Spinner/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../FormatDate/DateFormat";
import ToastWithClose from "../../Toast/ToastWithClose";
import { triggerToast } from "../../Toast/TriggerToast";
import { createVivaEngageMessage } from "./helpers/VivaEngageHelper";
import NotFoundRequest from "../../PageNotFound/NotFoundRequest";

const SynonymsForm = () => {
  const initialForm = {
    term: "",
    synonyms: [],
    request_id: 0,
    status: "New",
    request_type: "Creation",
    owners: [],
    creationdate: undefined,
    lastmodificationdate: undefined,
    history: [],
    synonyms_implemented_id: null,
    yammerthreadid: null,
    owneruserid: null
  };
  let { id } = useParams();
  const [idFromUrl, setIdfromUrl] = useState(id);
  const [theme] = useContext(ThemeContext);
  const store = useContext(StoreContext);
  const [isTermValid, setIsTermValid] = useState({ state: undefined });
  const [form, setForm] = useState(initialForm);
  const itsFromContentSearcher = store.state.requestToEditSYN?.contentSearcher;
  const [isDataLoading, setIsDataLoading] = useState(itsFromContentSearcher);
  const [savingData, setSavingData] = useState(false);
  const [validating, setValidating] = useState(false);
  const [isFormReadOnly, setIsFormReadOnly] = useState(false);
  const [displayGrayPills, setDisplayGrayPills] = useState(false);
  const [loadFromRedirectTrigger, setLoadFromRedirectTrigger] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [autoFillWarning, setAutoFillWarning] = useState(false);
  const [notFoundMessage, setNotFoundMessage] = useState(false);

  const isAdmin = store.state.isAdmin;
  const [modal, setModal] = useState({
    show: false,
    msg: "",
    onClose: () => {},
    sndBtn: false
  });
  const navigate = useNavigate();

  if (!store.state.hasOwnProperty("requestToEditSYN")) {
    store.state.requestToEditSYN = {
      canEdit: false,
      request_id: -1,
      contentSearcher: false,
      itsFromManageMyRequest: false
    };
  }

  const itsFromManageMyRequest =
    store.state.requestToEditSYN.itsFromManageMyRequest;

  const autoFillMessage = `This term and it´s synonyms already exists, if you want to add another synonym complete the field and perform your update`;
  //Inputs
  const handleAddSynonym = (synonym) => {
    const validated = SynonymsValidations.validateSynonym(
      synonym,
      form.synonyms,
      store.services.synonymsService
    );

    if (synonym.toLowerCase() === form.term.toLocaleLowerCase()) {
      triggerModal("Term and Synonyms can´t be the same", closeModal);
      return;
    } else if (!validated.state) {
      triggerModal(validated.msg, closeModal);
    } else {
      const newSynonyms = [...form.synonyms, synonym];
      setForm({ ...form, synonyms: newSynonyms });
    }
  };
  const handleTermChange = (event) => {
    let term = event.target.value;
    setForm({ ...form, term: term });
  };
  const handleDeletePill = (index) => {
    const newPills = form.synonyms.filter((_, i) => i !== index);
    setForm({ ...form, synonyms: newPills });
  };

  const setFormMode = (form) => {
    let currentUserEid = localStorage.getItem("searchtools.eid");
    let isOwner = form.owners.includes(currentUserEid);

    if (form.status === "New" && (isAdmin || isOwner)) {
      setDisplayGrayPills(false);
      setIsFormReadOnly(false);
    }

    let contentSearcherValues = null;
    let contentSearcherData = null;

    try {

      contentSearcherValues = localStorage.getItem("contentSearcherValuesSy");
  
      if (contentSearcherValues !== null && contentSearcherValues !== "") {
  
        contentSearcherData = JSON.parse(contentSearcherValues);
      }
      
    } catch (error) {
      
    }

    let fromContentSearcher = null;

    if(contentSearcherData === null){
      fromContentSearcher = itsFromContentSearcher;
    }else{
      fromContentSearcher = itsFromContentSearcher || contentSearcherData.contentSearcher;
    }
  
    if (
      form.status === "Cancelled" ||
      form.status === "Not Approved" ||
      (form.status === "Implemented" &&
        !store.state.requestToEditSYN.canEdit) ||
      (form.status === "Implemented" &&
        !fromContentSearcher &&
        !itsFromManageMyRequest)
    ) {
      setDisplayGrayPills(true);
      setIsFormReadOnly(true);
    } else {
      setIsFormReadOnly(false);
    }

    if(store.state.requestToEditSYN !== undefined && store.state.requestToEditSYN !== null){
      
      if (
        !store.state.requestToEditSYN.contentSearcher &&
        !store.state.requestToEditSYN.itsFromManageMyRequest &&
        idFromUrl !== undefined
      ) {
        store.state.requestToEditSYN.canEdit = false;
        store.state.requestToEditSYN.synonym_term = "";
      } else {
        store.state.requestToEditSYN.contentSearcher = false;
        store.state.requestToEditSYN.itsFromManageMyRequest = false;
      }

    }else{
      if (
        !contentSearcherData.contentSearcher &&
        !contentSearcherData.itsFromManageMyRequest &&
        idFromUrl !== undefined
      ) {
        contentSearcherData.canEdit = false;
        contentSearcherData.synonym_term = "";
      } else {
        contentSearcherData.contentSearcher = false;
        contentSearcherData.itsFromManageMyRequest = false;
      }
    }

  };

  const autoFillForm = async (id, form) => {
    try {
      if (!form) {
        let r = await getSynonymsRequest(id);

        let totalHits = r.data.hits.total.value;
        if (totalHits === 0) {
          setIsDataLoading(false);
          setNotFoundMessage(true);
        } else {
          if (r.data.hits.total.value > 0) {
            form = r.data.hits.hits[0]["_source"];
          } else {
            navigate("/promote/synonyms/request");
          }
        }
      }
      let newForm = form;
      setForm(newForm);
      setFormMode(newForm);
      setIsDataLoading(false);
      setLoadFromRedirectTrigger(!loadFromRedirectTrigger);

      setTimeout(() => {
        localStorage.removeItem("contentSearcherValuesSy");
      }, 3000);
    } catch (error) {
      console.error("Error in autoFillForm:", error);
      setIsDataLoading(false);
      setNotFoundMessage(true);
    }
  };

  const createYammerMsg = async (_reqStatus, _action, _reqId, _term) => {
    let finalMsg = "";
    const urlToRequest = `${process.env.REACT_APP_SEARCHTOOLS_URL}/promote/synonyms/request/${_reqId}`;

    const lastOwnerEid = localStorage.getItem("searchtools.eid");
    let ownerYammerId = "ERROR - NO USER TO TAG";

    try {
      ownerYammerId =
        await store.services.yammerService.GetOwnerUserIdForYammer(
          lastOwnerEid
        );
    } catch (error) {
      console.log("error on getting user id viva engage ", error);
    }
    const initialMsg = `A new Synonym request was submitted ${
      isAdmin ? "and implemented " : ""
    }, (${_reqId} - ${_term}) \n ${urlToRequest} - ${ownerYammerId}`;

    let request = {
      action: _action,
      id: _reqId,
      term: _term,
      urlToRequest: urlToRequest,
      ownerYammerId: ownerYammerId
    };

    switch (_reqStatus) {
      case "Remove":
      case "Submit for Removal":
      case "Implement":
        return (finalMsg = [createVivaEngageMessage(request)]);
      case "Cancelled":
      case "Not Approved":
        let messages = [];
        if (form.yammerthreadid === null) {
          messages.push(initialMsg);
        }
        let commentMsg = createVivaEngageMessage(request);
        messages.push(commentMsg);

        return messages;

      case "New":
        if (_action === "Save Changes" && form.yammerthreadid === null) {
          _action = "Save Changes - No Thread";
        }
        if (_action === "Creation") {
          finalMsg = [initialMsg];

          return finalMsg;
        } else if (_action === "Save Changes") {
          finalMsg = [`This request was modified. ${ownerYammerId}`];

          return finalMsg;
        } else if (_action === "Save Changes - No Thread") {
          finalMsg = [
            `A new Synonym request was submitted, (${_reqId} - ${_term}) \n ${urlToRequest} - ${ownerYammerId}`
          ];

          return finalMsg;
        } else if (_action === "Update") {
          finalMsg = [
            `A Synonym update request was submitted, (${_reqId} - ${_term}) \n ${urlToRequest} - ${ownerYammerId}`
          ];
          return finalMsg;
        }
        break;

      case "Implemented":
        if (_action === "Implement") {
          finalMsg = [
            `Your request has been implemented. Please review it for more details. ${ownerYammerId}`
          ];
          return finalMsg;
        } else if (isAdmin && _action === "Implement") {
          finalMsg = [
            `A Synonym request was submitted and implemented, (${_reqId} - ${_term}) \n ${urlToRequest} - ${ownerYammerId}
        `
          ];
          return finalMsg;
        } else if (_action === "Implement Change - regular") {
          finalMsg = [
            `Your update request has been implemented. Please review it for more details. ${ownerYammerId}`
          ];
          return finalMsg;
        } else if (_action === "Implement Change - admin") {
          finalMsg = [
            `A Synonym update request was submitted and implemented, (${_reqId} - ${_term}) \n ${urlToRequest} - ${ownerYammerId}
        `
          ];
          return finalMsg;
        }

        break;

      default:
        break;
    }
  };

  //Request
  const handleSubmit = async (status, type, e) => {
    e.preventDefault();
    setSavingData(true);
    const action = e.target.innerHTML;
    const synonymsRequest = await buildRequestJson(status, type, action);
    var synonymsImplemented = {};

    const body = [synonymsRequest, synonymsImplemented];

    if (synonymsRequest.request_id <= 0) {
      try {
        const result = await store.services.synonymsService.SaveSynonyms(body);

        const messageToPost = await createYammerMsg(
          status,
          type,
          result.data._id,
          synonymsRequest.term
        );

        await store.services.yammerService.PostToYammer(
          result.data._id,
          process.env.REACT_APP_PENDING_SYNONYMS_IDX,
          "_doc",
          false,
          messageToPost
        );

        setSavingData(false);
        triggerModal(
          "Synonym request #" +
            result.data._id +
            ' was successfully submitted. Upon clicking OK, you will be redirected to the "Submitted requests" view where you can track the status of this request.',
          () => navigate("/myrequests")
        );
      } catch {
        setSavingData(false);
        triggerModal(
          "Error has been encountered. Try again later.",
          closeModal
        );
      }
    } else {
      if (!isAdmin) {
        synonymsRequest.owners.push(localStorage.getItem("searchtools.eid"));
        const noRepeated = new Set(synonymsRequest.owners);
        synonymsRequest.owners = [...noRepeated];
      }
      try {
        var r = null;
        var modalMsg = "";
        const isActive = form.status === "Implemented";
        if (isActive) {
          r = await store.services.synonymsService.SaveSynonyms(body, 0);
          let messageToPost;
          if (action === "Submit for Removal") {
            messageToPost = await createYammerMsg(
              action,
              action,
              r.data._id,
              form.term
            );

            await store.services.yammerService.PostToYammer(
              r.data._id,
              process.env.REACT_APP_PENDING_SYNONYMS_IDX,
              "_doc",
              false,
              messageToPost
            );
          } else {
            messageToPost = await createYammerMsg(
              status,
              "Update",
              r.data._id,
              form.term
            );

            await store.services.yammerService.PostToYammer(
              r.data._id,
              process.env.REACT_APP_PENDING_SYNONYMS_IDX,
              "_doc",
              false,
              messageToPost
            );
          }

          modalMsg =
            type !== "Removal"
              ? "Synonym request ID #" + r.data._id + " has been updated."
              : "Synonym has been submitted for removal.";
        } else {
          r = await store.services.synonymsService.SaveSynonyms(
            body,
            form.request_id
          );

          if (form.yammerthreadid !== null) {
            const messageToPost = await createYammerMsg(
              status,
              action,
              form.request_id,
              form.term
            );

            await store.services.yammerService.PostYammerComment(
              parseInt(form.yammerthreadid),
              messageToPost
            );
          } else if (form.yammerthreadid === null) {
            const messageToPost = await createYammerMsg(
              status,
              action,
              form.request_id,
              form.term
            );

            await store.services.yammerService.PostToYammer(
              form.request_id,
              process.env.REACT_APP_PENDING_SYNONYMS_IDX,
              "_doc",
              messageToPost.length > 1 ? true : false,
              messageToPost
            );
          }

          modalMsg = "Synonym request ID #" + r.data._id + " has been updated.";
        }

        setSavingData(false);
        triggerModal(modalMsg, () => navigate("/myrequests"));
      } catch (error) {
        setSavingData(false);
        triggerModal(
          "Error has been encountered. Try again later.",
          closeModal
        );
      }
    }
  };
  const implementSynonyms = async (rqstStatus, rqstType, event) => {
    let action = event.target.innerHTML;
    event.preventDefault();
    setSavingData(true);
    let requestBody = [];
    let r;

    if (rqstType === "Removal" || form.request_type === "Removal") {
      rqstType = "Removal";
      setForm({ ...form, request_id: 0 });
    }
    let pendingSynonymsReq = await buildRequestJson(
      rqstStatus,
      rqstType,
      action
    );
    if (!form.synonyms_implemented_id) {
      //creation
      let synonymsReq = {};
      synonymsReq.cleanstatus = "Active";
      synonymsReq.keyword = form.term;
      synonymsReq.synonyms = form.synonyms;
      synonymsReq.owners = pendingSynonymsReq.owners;

      requestBody.push(pendingSynonymsReq);
      requestBody.push(synonymsReq);
      try {
        r = await store.services.synonymsService.SaveSynonyms(
          requestBody,
          form.request_id
        );

        if (form.yammerthreadid !== null) {
          // if it has thread id post comment
          try {
            const messageToPost = await createYammerMsg(
              "Implemented",
              "Implement",
              form.request_id,
              form.term
            );

            await store.services.yammerService.PostYammerComment(
              parseInt(form.yammerthreadid),
              messageToPost
            );
          } catch (error) {
            console.log(
              "error on posting comment on viva engage implement synonym"
            );
          }
        } else if (pendingSynonymsReq.synonyms_implemented_id === null) {
          //If it's admin autoimplementation from zero (no yammer thread)
          const messageToPost = await createYammerMsg(
            "New",
            "Creation",
            r.data._id,
            form.term
          );

          try {
            await store.services.yammerService.PostToYammer(
              r.data._id,
              process.env.REACT_APP_PENDING_SYNONYMS_IDX,
              "_doc",
              false,
              messageToPost
            );
          } catch (error) {
            console.log("error on viva engage post creation , trying again...");
            await store.services.yammerService.PostToYammer(
              r.data._id,
              process.env.REACT_APP_PENDING_SYNONYMS_IDX,
              "_doc",
              false,
              messageToPost
            );
          }
        }
        const action =
          pendingSynonymsReq.request_type === "Update" ? "updated" : "saved";
        setSavingData(false);
        triggerModal(
          `Synonyms request ID #${r.data._id} has been ${action} and implemented.`,
          () => navigate("/myrequests")
        ); //go to /myrequest when closing modal
      } catch {
        setSavingData(false);
        triggerModal(
          "Error has been encountered. Try again later.",
          closeModal //don't do anything when closing modal
        );
      }
    } else {
      //   update, removal
      var synonymsjson = {};
      synonymsjson.doc = {};
      let msg;
      requestBody.push(pendingSynonymsReq);
      try {
        if (rqstType === "Removal") {
          requestBody.push({});
          pendingSynonymsReq.synonyms_implemented_id = null;
          r = await store.services.synonymsService.DeleteSynonyms(
            requestBody,
            form.synonyms_implemented_id
          );

          try {
            const messageToPost = await createYammerMsg(
              action,
              action,
              r.data._id,
              form.term
            );

            if (action === "Remove") {
              await store.services.yammerService.PostToYammer(
                r.data._id,
                process.env.REACT_APP_PENDING_SYNONYMS_IDX,
                "_doc",
                false,
                messageToPost
              );
            } else {
              await store.services.yammerService.PostYammerComment(
                parseInt(form.yammerthreadid),
                messageToPost
              );
            }
          } catch (error) {
            console.log(
              "error on posting comment on viva engage implement synonym removal"
            );
          }
        } else {
          synonymsjson.doc.synonyms = form.synonyms;
          synonymsjson.doc.keyword = form.term;
          synonymsjson.doc.owners = pendingSynonymsReq.owners;
          requestBody.push(synonymsjson);
          r = await store.services.synonymsService.Update(
            requestBody,
            form.request_id,
            form.synonyms_implemented_id
          );

          let reqAction = "";

          if (
            form.request_type === "Creation" &&
            form.status === "Implemented"
          ) {
            reqAction = "Implement Change - admin";
          } else if (
            form.request_type === "Update" &&
            form.status === "Implemented"
          ) {
            reqAction = "Implement Change - admin";
          } else if (form.request_type === "Update" && form.status === "New") {
            reqAction = "Implement Change - regular";
          }

          if (form.yammerthreadid !== null) {
            try {
              const messageToPost = await createYammerMsg(
                "Implemented",
                reqAction,
                form.request_id,
                form.term
              );

              if (reqAction === "Implement Change - admin") {
                // always make new post when admin autoimplements
                await store.services.yammerService.PostToYammer(
                  r.data._id,
                  process.env.REACT_APP_PENDING_SYNONYMS_IDX,
                  "_doc",
                  false,
                  messageToPost
                );
              } else {
                await store.services.yammerService.PostYammerComment(
                  parseInt(form.yammerthreadid),
                  messageToPost
                );
              }
            } catch (error) {
              console.log(
                "error on posting comment on viva engage implement synonym update 1"
              );
            }
          } else {
            try {
              const messageToPost = await createYammerMsg(
                "Implemented",
                reqAction,
                form.request_id,
                form.term
              );

              if (reqAction === "Implement Change - regular") {
                const postMesage = await createYammerMsg(
                  "New",
                  "Update",
                  form.request_id,
                  form.term
                );

                postMesage.push(messageToPost[0]);

                await store.services.yammerService.PostToYammer(
                  r.data._id,
                  process.env.REACT_APP_PENDING_SYNONYMS_IDX,
                  "_doc",
                  true,
                  postMesage
                );
              } else {
                await store.services.yammerService.PostToYammer(
                  r.data._id,
                  process.env.REACT_APP_PENDING_SYNONYMS_IDX,
                  "_doc",
                  false,
                  messageToPost
                );
              }
            } catch (error) {
              console.log(
                "error on posting comment on viva engage implement synonym update 2"
              );
            }
          }
        }

        if (rqstType === "Removal") {
          msg = "Synonym has been removed.";
        } else {
          const verifyOnSearch =
            rqstType !== "Update" ? " Verify on search." : "";
          msg = `Synonym request ID #${r.data._id} has been updated and implemented.${verifyOnSearch}`;
        }
        setSavingData(false);
        triggerModal(msg, () => navigate("/myrequests"));
      } catch (error) {
        setSavingData(false);
        triggerModal(
          "Error has been encountered. Try again later.",
          closeModal
        );
        return;
      }
    }
    let requestToAdd = requestBody[1];

    store.services.synonymsService.UpdateTemplate(requestToAdd);
  };

  const buildRequestJson = async (status, type, action) => {
    const currentUTCDate = new Date().toISOString();
    const creationDate = form.creationdate || currentUTCDate;
    const currentUser = localStorage.getItem("searchtools.eid");
    var owners = form.owners;
    var history;
    const isAutoimplement =
      action === "Implement" &&
      (form.request_id === 0 || form.status === "Implemented");
    if (isAutoimplement) {
      history = [
        { status: status, user: currentUser, dateUTC: currentUTCDate }
      ];
      owners = [currentUser];
    } else if (
      action.includes("Request") ||
      action.includes("Submit") ||
      action === "Save Changes"
    ) {
      owners.push(localStorage.getItem("searchtools.eid"));
      const noRepeated = new Set(owners);
      owners = [...noRepeated];
    }

    if (!isAutoimplement) {
      history = [
        ...form.history,
        { status: status, user: currentUser, dateUTC: currentUTCDate }
      ];
    }
    if (
      form.synonyms_implemented_id !== null &&
      form.synonyms_implemented_id !== undefined
    ) {
      const implementedSynonym =
        await store.services.synonymsService.GetImplementedSynonymsById(
          form.synonyms_implemented_id
        );
      form.synonyms_implemented_id =
        implementedSynonym.data.hits.hits.length === 0
          ? null
          : form.synonyms_implemented_id;
    }
    return {
      term: form.term.trim(),
      synonyms: form.synonyms,
      request_id: form.request_id,
      status: status,
      request_type: type,
      owners: owners,
      creationdate: creationDate,
      history: history,
      lastmodificationdate: currentUTCDate,
      synonyms_implemented_id: form.synonyms_implemented_id,
      yammerthreadid: form.yammerthreadid,
      owneruserid: null
    };
  };

  const getSynonymsRequest = async (reqId) => {
    return await store.services.synonymsService.GetPendingSynonymsReq(reqId);
  };
  const getLastImplementedRequest = async (implementedId, request) => {
    let r = await store.services.synonymsService.GetPendingSynonymsReq(
      undefined,
      implementedId
    );
    var mostRecentRequest;
    const implementedRequests = r.data.hits.hits.filter(
      (req) => req._source.status === "Implemented"
    );
    if (implementedRequests.length > 0) {
      mostRecentRequest = implementedRequests.reduce((prev, curr) => {
        const prevDate = new Date(prev._source.lastmodificationdate);
        const currDate = new Date(curr._source.lastmodificationdate);
        return prevDate > currDate ? prev : curr;
      });
      store.state.requestToEditSYN.canEdit = true;
      setAutoFillWarning(true);
      setForm(mostRecentRequest._source);
      setFormMode(mostRecentRequest._source);
    } else {
      //There is no pending request linked to this implemented request
      mostRecentRequest = {
        _source: {
          term: request.keyword,
          synonyms: request.synonyms,
          request_id: 0,
          status: "Implemented",
          request_type: "Creation",
          owners: [],
          creationdate: undefined,
          lastmodificationdate: undefined,
          history: [],
          synonyms_implemented_id: request.synonyms_implemented_id,
          yammerthreadid: null,
          owneruserid: null
        }
      };
    }

    return mostRecentRequest._source;
  };
  //Tooltips
  const deleteOldTooltips = () => {
    //delete unparented tooltips from the dom
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  //Modals
  const triggerModal = (message, callback, firstBtn = null, sndBtn = null) => {
    if (firstBtn === null) {
      setModal({
        show: true,
        msg: message,
        onClose: callback
      });
    } else {
      setModal({
        show: true,
        msg: message,
        onClose: callback,
        fstBtn: firstBtn,
        sndBtn: sndBtn
      });
    }
  };

  const closeModal = () => {
    setModal({ ...modal, show: false, sndBtn: false });
  };
  const show2ButtonsModal = () => {
    return modal.show && modal.sndBtn;
  };
  const showToast = () => {
    return (
      (autoFillWarning &&
        form.status !== "New" &&
        store.state.requestToEditSYN?.contentSearcher) ||
      (!store.state.requestToEditSYN?.contentSearcher && form.status !== "New")
    );
  };

  useEffect(() => {
    deleteOldTooltips();
  }, []);
  useEffect(() => {
    const tooltipClass = theme === "dark" ? "syn-tooltip-dark" : "";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover focus",
        customClass: tooltipClass,
        html: true
      });
    });
  });
  useEffect(() => {
    var timeout;
    if (form.term !== "" && !isFormReadOnly) {
      setIsDataLoading(false);
      setValidating(true);
      timeout = setTimeout(() => {
        SynonymsValidations.validateTerm(
          form.term,
          form.synonyms_implemented_id,
          store.services.synonymsService
        ).then((isValid) => {
          store.state.requestToEditSYN.canEdit = true;
          if (isValid.requestId && form.request_id !== isValid.requestId) {
            setIsDataLoading(true);
            getLastImplementedRequest(isValid.requestId, isValid.request).then(
              (req) => {
                autoFillForm(null, req);
                setIsFormReadOnly(false);
                setIsDataLoading(false);
                setIsTermValid({ state: "valid" });
                setAutoFillWarning(true);
              }
            );
          } else {
            setIsTermValid(isValid);
            setIsDataLoading(false);
          }
          setValidating(false);
          if (
            store.state.requestToEditSYN.contentSearcher ||
            store.state.requestToEditSYN.itsFromManageMyRequest
          ) {
            store.state.requestToEditSYN.canEdit = false;
            store.state.requestToEditSYN.synonym_term = "";
          }
        });
      }, 650);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [form.term]);
  useEffect(() => {
    if (idFromUrl !== undefined) {
      setIsDataLoading(true);
      autoFillForm(idFromUrl, null);
    }
  }, [idFromUrl]);

  useEffect(() => {
    setEnableButton(
      form.term &&
        form.synonyms.length &&
        isTermValid.state === "valid" &&
        !validating &&
        !isDataLoading
    );
  }, [form.term, form.synonyms, isTermValid, validating, isDataLoading]);

  useEffect(() => {

    try {

      // loading requests from content searcher in a new tab
      let contentSearcherValues = localStorage.getItem("contentSearcherValuesSy");
  
      if (contentSearcherValues !== null && contentSearcherValues !== "") {
  
        let contentSearcherData = JSON.parse(contentSearcherValues);

        store.state.requestToEditSYN = {
          canEdit: contentSearcherData.canEdit,
          synonym_term: contentSearcherData.synonym_term,
          contentSearcher: true,
          itsFromManageMyRequest: contentSearcherData.itsFromManageMyRequest
        };
      
      }
      
    } catch (error) {
      
    }

    if (itsFromContentSearcher || store.state.requestToEditSYN?.contentSearcher) {
      store.services.synonymsService
        .GetSynonymsByKeyword(store.state.requestToEditSYN.synonym_term)
        .then((synonymsByKeyword) => {
          const existingTerms = synonymsByKeyword?.data?.hits?.hits;
          if (existingTerms && existingTerms.length > 0) {
            const existingTerm = existingTerms.find(
              (doc) =>
                doc._source.keyword.toLowerCase() ===
                store.state.requestToEditSYN.synonym_term.toLowerCase()
            );
            if (existingTerm) {
              getLastImplementedRequest(
                existingTerm._source.synonyms_implemented_id,
                existingTerm._source
              ).then((req) => {
                autoFillForm(null, req);
                setIsTermValid({ state: "valid" });
                setAutoFillWarning(true);
                setIsDataLoading(false);
              });
            } else {
              setIsDataLoading(false);
              store.state.requestToEditSYN.synonym_term = "";
            }
          }
        });
    }
  }, []);

  useEffect(() => {
    if (showToast()) {
      triggerToast(10000, 0, "autoFillMessage-synonyms");
    }
  }, [autoFillWarning]);
  return (
    <div>
      {notFoundMessage ? (
        <NotFoundRequest />
      ) : (
        <>
          {isDataLoading ? (
            <div className="d-flex justify-content-center loading-spinner-main-div">
              <div
                className="spinner-border text-light-mode-purple"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <form
              className="synonyms_form"
              id="synonym-form"
              data-testid="synonym-form"
              onSubmit={(e) => e.preventDefault()}
            >
              {parseInt(idFromUrl) > 0 && (
                <div
                  id="request-info-syn"
                  className="ht-request-info-parent-div"
                >
                  <div className="syn-request-info-title">
                    Synonyms Request ID {form.request_id}
                  </div>
                  <div className="request-info-child-syn">
                    <div className="request-info-block">
                      RequestType: <p>{form.request_type}</p>
                    </div>
                    <div className="request-info-block">
                      Status: <p>{form.status}</p>
                    </div>
                    <div className="request-info-block">
                      Submit Time:{" "}
                      <p>{formatDate(form?.lastmodificationdate)}</p>
                    </div>
                  </div>
                </div>
              )}
              <AddTermField
                tooltipFor={"add-term-input"}
                onTermChange={handleTermChange}
                isValid={isTermValid}
                value={form.term}
                disableFieldProp={isFormReadOnly}
              />
              <AddSynonymsField
                tooltipFor={"add-synonym-input"}
                onAddSynonym={handleAddSynonym}
                disableFieldProp={isFormReadOnly}
              />
              <SynonymsPills
                pills={form.synonyms}
                onDelete={handleDeletePill}
                displayGrayPills={displayGrayPills}
                disableFieldProp={isFormReadOnly}
                canEditForm={store.state.requestToEditSYN.canEdit}
              />
              {!isFormReadOnly && (
                <SynonymsButtons
                  isBtnDisabled={!enableButton}
                  submit={handleSubmit}
                  implementSynonyms={implementSynonyms}
                  triggerModal={triggerModal}
                  form={form}
                  disableFieldProp={isFormReadOnly}
                  loadFromRedirectTrigger={loadFromRedirectTrigger}
                  itsFromManageMyRequest={itsFromManageMyRequest}
                  idFromUrl={idFromUrl}
                />
              )}
              {show2ButtonsModal() ? (
                <ModalCustom
                  noHeader={true}
                  modal_msg={modal.msg}
                  onClose={() => {
                    modal.onClose();
                    closeModal();
                  }}
                  secondOption={true}
                  onClickSecondOpt={closeModal}
                  firstOptText={modal.fstBtn}
                  secondOptText={"Cancel"}
                  btn_1_class={
                    theme === "dark"
                      ? "btn btn-dark-mode-purple"
                      : "btn btn-light-mode-purple"
                  }
                  btn_2_class={
                    theme === "dark"
                      ? "btn btn-dark-mode-purple"
                      : "btn btn-light-mode-purple"
                  }
                />
              ) : modal.show ? (
                <ModalCustom
                  noHeader={true}
                  modal_msg={modal.msg}
                  onClose={modal.onClose}
                  btn_1_class={
                    theme === "dark"
                      ? "btn btn-dark-mode-purple"
                      : "btn btn-light-mode-purple"
                  }
                />
              ) : (
                <></>
              )}
              {savingData ? (
                <Spinner />
              ) : showToast() ? (
                <ToastWithClose
                  id="toast-synonyms"
                  data-testid="toast-synonyms"
                  text={autoFillMessage}
                  toastKey="autoFillMessage-synonyms"
                />
              ) : (
                <></>
              )}
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default SynonymsForm;
