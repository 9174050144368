import { useEffect, useState, isValidElement } from "react";
import ModalCustom from "../../../Modal/ModalCustom";
import { copyToClipboard } from "../../../CopyToClipboard/CopyToClipboard";
import Toast from "../../../Toast/Toast";
import services from "../../../../store/services";
import { Tooltip } from "bootstrap";
import { triggerToast } from "../../../Toast/TriggerToast";

const KeywordsField = (props) => {
  const [cssState, setCssState] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const MAX_KEYWORDS_ALLOWED = 20;
  const urlCheckerService = services.urlCheckerService;

  const addKeyword = async (event) => {
    let elemRef = {};
    elemRef = document.getElementById("ctc-keyword-input");

    let regex = urlCheckerService.textRegex;
    if (elemRef.value.match(regex)) {
      setCssState(" is-invalid ");
      setStatusMessage('Please do not include the special characters \\ and "');
    } else {
      setCssState("");
      setStatusMessage("");
    }

    if (event.key === "Enter" || event.type === "click") {
      event.preventDefault();
      let keywordsList = elemRef.value.split(",");
      let kwArr = [...selectedKeywords];
      let regex = urlCheckerService.textRegex;

      if (elemRef.value.match(regex)) {
        setModalMsg('Please do not include the special characters \\ and "');
        setShowModal(true);
        setStatusMessage("");
        setCssState("");
        elemRef.value = "";
        return;
      }
      if (selectedKeywords.length >= MAX_KEYWORDS_ALLOWED) {
        setModalMsg(`Only ${MAX_KEYWORDS_ALLOWED} keywords are allowed.`);
        setShowModal(true);
        return;
      }

      if (keywordsList.length > 0) {
        let refinersHtmlList = document.querySelectorAll("#refiner-on-list");
        let currRefinersSelected = [];
        for (let i = 0; i < refinersHtmlList.length; i++) {
          const refiner = refinersHtmlList[i].textContent
            .trim()
            .replace("x", "");
          currRefinersSelected.push(refiner);
        }

        let countryHtmlList = document.querySelectorAll("#country-on-list");
        let currLocationsSelected = [];
        for (let j = 0; j < countryHtmlList.length; j++) {
          const location = countryHtmlList[j].children[0].innerHTML;
          currLocationsSelected.push(location);
        }

        let keywordsFromHTMLList =
          document.querySelectorAll("#keyword-on-list");
        let currKwFromHTML = [];
        for (let w = 0; w < keywordsFromHTMLList.length; w++) {
          const location = keywordsFromHTMLList[w].children[0].innerHTML;
          currKwFromHTML.push(location);
        }

        if (
          currRefinersSelected.length === 0 ||
          currLocationsSelected.length === 0
        ) {
          elemRef.value = "";
          setModalMsg("Please select a Location and a Refiner first.");
          setShowModal(true);
          return;
        }

        for (let i = 0; i < keywordsList.length; i++) {
          let valueToPush = keywordsList[i];
          let lowercaseKw = valueToPush.trim().toLowerCase();
          let upperCaseKw = valueToPush.trim().toUpperCase();
          if (
            currKwFromHTML.indexOf(valueToPush) === -1 &&
            currKwFromHTML.indexOf(lowercaseKw) === -1 &&
            currKwFromHTML.indexOf(upperCaseKw) === -1
          ) {
            if (lowercaseKw !== undefined || lowercaseKw.length > 0) {
              let res = await props.tcService.GetTopicCardsByKeyword(
                lowercaseKw,
                currRefinersSelected,
                currLocationsSelected
              );

              if (res.data.hits.hits.length > 0) {
                // red kw (invalid)
                if (
                  selectedKeywords.length === 0 ||
                  currKwFromHTML.length === 0
                ) {
                  kwArr.push(valueToPush + "_RED_KW_FLAG");
                  setCssState(" is-invalid ");
                  setStatusMessage(
                    "Please enter at least one valid, relevant keyword in order to successfully submit this Topic Card request."
                  );
                  props.updateFormState(false, "keywords");
                } else if (
                  selectedKeywords.indexOf(valueToPush + "_RED_KW_FLAG") === -1
                ) {
                  kwArr.push(valueToPush + "_RED_KW_FLAG");
                  props.updateFormState(true, "keywords");
                  props.changeCssForKwInput(true, "");
                } else if (
                  selectedKeywords.indexOf(valueToPush + "_RED_KW_FLAG") >= 0
                ) {
                  setModalMsg("You have already added this keyword.");
                  setShowModal(true);
                }
              } else if (res.data.hits.hits.length === 0) {
                if (selectedKeywords.length === 0) {
                  kwArr.push(valueToPush);
                  setCssState(" is-valid ");
                  props.updateFormState(true, "keywords");
                  props.changeCssForKwInput(true, "");
                } else {
                  kwArr.push(valueToPush);
                  props.updateFormState(true, "keywords");
                  props.changeCssForKwInput(true, "");
                }
              }
            }
          } else {
            elemRef.value = "";
            setModalMsg("You have already added this keyword.");
            setShowModal(true);
            return;
          }
        }

        elemRef.value = "";
        setSelectedKeywords(kwArr);
        props.userEnteredKw();
      }
    } else if (elemRef !== isValidElement) {
      return;
    }
  };

  const removeKeyword = (event) => {
    event.preventDefault();
    let kwArr = [...selectedKeywords];
    kwArr = kwArr.filter((el) => {
      let isRedKw = el.includes("_RED_KW_FLAG");

      if (isRedKw) {
        el = el.replace("_RED_KW_FLAG", "");
      }
      if (el !== event.target.value) {
        return el;
      }
    });

    setSelectedKeywords(kwArr);
    props.kwRevalidation();
  };

  const createTooltip = (event) => {
    props.createNewTooltip(event);
  };

  const clipboardHandler = (event) => {
    copyToClipboard(event);
    triggerToast(1000, 0, "copyToClipboard");
  };

  const keywordsList = selectedKeywords.map((opt) => {
    let isRedKw = opt.includes("_RED_KW_FLAG");

    if (isRedKw) {
      opt = opt.replace("_RED_KW_FLAG", "");
    }

    let idStatusClass = isRedKw ? " red-kw-class " : " green-kw-class ";
    let btnStatusClass = isRedKw ? "btn-danger " : "btn-success ";

    return (
      <div
        id="keyword-on-list"
        data-testid="testid-keyword-on-list"
        aria-label="keyword-on-list"
        key={opt + "_div"}
        className={"btn-group " + props.pills_style}
        role="group"
      >
        {isRedKw ? (
          <button
            aria-label={"keyword " + opt}
            key={opt + "_btn_opt_key"}
            className={
              "btn " + btnStatusClass + props.btn_font_weigth + idStatusClass
            }
            type="button"
            onClick={clipboardHandler}
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            data-bs-title={
              "Another Custom Topic Card is already associated with this keyword. It will be considered, but may not be implemented."
            }
            onMouseEnter={createTooltip}
          >
            {opt}
          </button>
        ) : (
          <button
            aria-label={"keyword " + opt}
            data-testid="kw-pill"
            key={opt + "_btn_opt_key"}
            className={
              "btn " + btnStatusClass + props.btn_font_weigth + idStatusClass
            }
            type="button"
            onClick={clipboardHandler}
          >
            {opt}
          </button>
        )}
        {props.readOnlyMode ? (
          <></>
        ) : (
          <button
            id={opt + "_remove_btn"}
            key={opt + "_remove_btn_key"}
            value={opt}
            className={"btn " + btnStatusClass + props.btn_font_weigth}
            type="button"
            aria-label={"remove " + opt + " from list"}
            onClick={removeKeyword}
          >
            x
          </button>
        )}
      </div>
    );
  });

  const onClickModalHandler = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (props.loadedvalue) {
      setSelectedKeywords(props.loadedvalue);
      props.kwRevalidation();
    }
  }, [props.loadedvalue]);

  const initToolTipForField = () => {
    const tooltipClass = props.isDarkMode ? "darkMode_tooltip" : "";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover focus",
        customClass: tooltipClass
      });
    });
  };

  const deleteOldTooltips = () => {
    //delete unparented tooltips from the dom
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  const plusButton = (
    <div
      onClick={addKeyword}
      onKeyDown={addKeyword}
      className={props.plus_icon_css}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      data-bs-title={"Add Keyword(s)"}
      onMouseEnter={initToolTipForField}
      onMouseLeave={deleteOldTooltips}
    >
      <svg
        className={"ctc_input_kw_add_btn"}
        viewBox="0 0 16 16"
        width={"1.4em"}
        height={"1.4em"}
        role="img"
        alt="icon"
        data-testid="ctc-kw-plus-icon"
        id="plus-icon"
        aria-label="Add Users(s)"
        value=""
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginBottom: "2px" }}
        tabIndex={0}
      >
        <g>
          <path
            fillRule="evenodd"
            d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
          ></path>
          <path
            fillRule="evenodd"
            d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
          ></path>
          <path
            fillRule="evenodd"
            d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
          ></path>
        </g>
      </svg>
    </div>
  );

  return (
    <div>
      {showModal ? (
        <ModalCustom
          onClick={onClickModalHandler}
          onClose={onClickModalHandler}
          modal_title={"Info"}
          modal_msg={modalMsg}
          secondOption={false}
          btn_1_class={
            props.isDarkMode
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
        />
      ) : (
        <div></div>
      )}
      <div className={props.input_and_button_div}>
        <input
          id="ctc-keyword-input"
          data-testid="testid-ctc-keyword-input"
          type="text"
          className={"form-control " + cssState}
          placeholder="Enter at least 1 related keyword (maximum of 20)"
          aria-label="Enter at least 1 related keyword (maximum of 20)"
          onKeyDown={addKeyword}
          disabled={props.readOnlyMode}
        />
        {props.readOnlyMode ? <></> : plusButton}
      </div>
      <div>
        <div
          className={
            "form-control" + cssState + " " + props.hidden_delegate_div
          }
        ></div>
        <div
          id="ctc-kw-error-feedback"
          className={"invalid-feedback " + " " + props.adjust_error_msg}
        >
          {statusMessage}
        </div>
      </div>
      <div
        id="ctc-selected-keywords"
        data-testid="testid-ctc-selected-keywords"
        className={props.selected_keywords_div}
        tabIndex={0}
      >
        {keywordsList}
      </div>
      <Toast
        toastText="Keyword copied to the clipboard!"
        toastKey="copyToClipboard"
      />
    </div>
  );
};

export default KeywordsField;
