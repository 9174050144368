import { useState, useEffect } from "react";
import ModalCustom from "../../../Modal/ModalCustom";
import services from "../../../../store/services";
import {
  EidSuggestion,
  selectSuggestionResult
} from "../../../EidSuggestion/EidSuggestion";
import { Tooltip } from "bootstrap";
import { triggerToast } from "../../../Toast/TriggerToast";
import Toast from "../../../Toast/Toast";
const INPUT_ELEM_ID = "ctc-delegates-input";

const DelegateField = (props) => {
  const [selectedDelegates, setSelectedDelegates] = useState([]);
  const [cssState, setCssState] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const urlCheckerService = services.urlCheckerService;

  const [eidSearchTrigger, setEidSearchTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const addDelegate = async (event, eidToAdd = null) => {
    let elemRef = {};
    elemRef = document.getElementById(`${INPUT_ELEM_ID}`);
    let delegateEid = "";

    if (eidToAdd === null) {
      delegateEid = elemRef.value.trim();
    } else {
      delegateEid = eidToAdd;
    }
    if (delegateEid !== "") {
      await setIsLoading(true);
    }

    elemRef.value = "";
    setEidSearchTrigger(!eidSearchTrigger);

    let regex = urlCheckerService.textRegex;
    let errorMsg = 'Please do not include the special characters \\ and "';

    if (delegateEid.match(regex)) {
      setCssState(" is-invalid ");
      setStatusMessage(errorMsg);
    } else {
      setCssState("");
      setStatusMessage("");
    }

    if (event.key === "Enter" || event.type === "click") {
      event.preventDefault();

      try {
        if (delegateEid.match(regex)) {
          setModalMsg(errorMsg);
          setShowModal(true);
        } else {
          setShowModal(false);
          if (delegateEid === undefined || delegateEid.length === 0) return;

          let currentUser = localStorage.getItem("searchtools.eid");
          if (delegateEid === currentUser) {
            setModalMsg("Please enter an EID other than yours");
            setShowModal(true);
          } else {
            let validationResult = await props.validateDelegate(
              delegateEid,
              props.searchToolsService
            );

            if (!validationResult.status) {
              setModalMsg("Please enter a valid EID");
              if (selectedDelegates.length === 0) {
                setCssState("");
              } else {
                setCssState(" is-invalid ");
              }
              setShowModal(true);
              return;
            }

            if (selectedDelegates.indexOf(delegateEid) === -1) {
              let delegatesArr = [...selectedDelegates];
              delegatesArr.push(delegateEid);

              setSelectedDelegates(delegatesArr);
              props.saveDelegatesData(validationResult.data);

              let state = "";
              if (validationResult.status === null) {
                state = "";
              } else {
                state = validationResult.status ? " is-valid " : " is-invalid ";
              }

              props.updateFormState(validationResult.status, "delegates");
              setCssState(state);
              setStatusMessage(validationResult.msg);
            } else {
              let validationResult = await props.validateDelegate(
                delegateEid,
                props.searchToolsService
              );

              if (!validationResult.status) {
                setModalMsg("Please enter a valid EID");
                if (selectedDelegates.length === 0) {
                  setCssState("");
                } else {
                  setCssState(" is-invalid ");
                }
                setShowModal(true);
                return;
              }

              if (selectedDelegates.indexOf(delegateEid) === -1) {
                let delegatesArr = [...selectedDelegates];
                delegatesArr.push(delegateEid);

                setSelectedDelegates(delegatesArr);
                props.saveDelegatesData(validationResult.data);

                let state = "";
                if (validationResult.status === null) {
                  state = "";
                } else {
                  state = validationResult.status
                    ? " is-valid "
                    : " is-invalid ";
                }

                props.updateFormState(validationResult.status, "delegates");
                setCssState(state);
                setStatusMessage(validationResult.msg);
              } else {
                setModalMsg(
                  "You have entered the same EID twice. Please enter a unique value per EID."
                );
                setShowModal(true);
              }
            }
          }
        }
      } catch (error) {
        console.log("Error in validateDelegates");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const removeDelegate = (event) => {
    event.preventDefault();
    let delegatesArr = [...selectedDelegates];
    delegatesArr = delegatesArr.filter((el) => {
      if (el !== event.target.value) {
        return el;
      }
    });

    setSelectedDelegates(delegatesArr);
    props.saveDelegatesData(event.target.value, true);

    if (delegatesArr.length === 0) {
      props.updateFormState(false, "delegates");
      let msg =
        "Please enter at least one delegate in order to successfully submit this Custom Topic Card request.";
      setStatusMessage(msg);
      setCssState(" is-invalid");
    }
  };
  useEffect(() => {
    if (props.loadedvalue) {
      setSelectedDelegates(props.loadedvalue);
      setCssState(" is-valid ");
    }
  }, [props.loadedvalue]);

  const initToolTipForField = () => {
    const tooltipClass = props.isDarkMode ? "darkMode_tooltip" : "";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover",
        customClass: tooltipClass
      });
    });
  };

  const deleteOldTooltips = () => {
    //delete unparented tooltips from the dom
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  const clipboardHandler = (event) => {
    props.copyToClipboard(event);
    triggerToast(1000, 1, "copyToClipboard");
  };

  const delegatesList = selectedDelegates.map((opt) => {
    return (
      <div
        id="delegate-on-list"
        data-testid="testid-delegate-on-list"
        aria-label="delegate-on-list"
        key={opt + "_div"}
        className={"btn-group " + props.pills_style}
        role="group"
      >
        <button
          aria-label={"delegate " + opt}
          key={opt + "_btn_opt_key"}
          className={"btn btn-success " + props.btn_font_weigth}
          type="button"
          onClick={clipboardHandler}
        >
          {opt}
        </button>

        {props.readOnlyMode ? (
          <></>
        ) : (
          <button
            data-testid="cross-pill"
            id={opt + "_remove_btn"}
            key={opt + "_remove_btn_key"}
            value={opt}
            className={"btn btn-success " + props.btn_font_weigth}
            type="button"
            aria-label={"remove " + opt + " from list"}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title={"Remove"}
            onMouseEnter={initToolTipForField}
            onMouseLeave={deleteOldTooltips}
            onClick={removeDelegate}
          >
            x
          </button>
        )}
      </div>
    );
  });

  const plusButton = (
    <div
      onClick={addDelegate}
      onKeyDown={addDelegate}
      className={props.plus_icon_css}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      data-bs-title={"Add Delegate"}
      onMouseEnter={initToolTipForField}
      onMouseLeave={deleteOldTooltips}
    >
      <svg
        className={"ctc_input_delegate_add_btn"}
        viewBox="0 0 16 16"
        width={"1.4em"}
        height={"1.4em"}
        role="img"
        alt="icon"
        data-testid="delegate-plus-icon"
        id="plus-icon"
        aria-label="Add Users(s)"
        value=""
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginBottom: "2px" }}
        tabIndex="0"
      >
        <g>
          <path
            fillRule="evenodd"
            d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
          ></path>
          <path
            fillRule="evenodd"
            d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
          ></path>
          <path
            fillRule="evenodd"
            d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
          ></path>
        </g>
      </svg>
    </div>
  );

  const onClickModalHandler = () => {
    setShowModal(false);
  };

  const getDelegateToSearch = () => {
    setEidSearchTrigger(!eidSearchTrigger);
  };

  const checkInputAction = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      addDelegate(event);
    } else {
      selectSuggestionResult(event, INPUT_ELEM_ID);
    }
  };

  return (
    <div>
      {showModal ? (
        <ModalCustom
          onClick={onClickModalHandler}
          onClose={onClickModalHandler}
          modal_title={"Info"}
          modal_msg={modalMsg}
          secondOption={false}
          noHeader={true}
          btn_1_class={
            props.isDarkMode
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
        />
      ) : (
        <div></div>
      )}
      <div className={props.input_and_button_div}>
        <input
          id={INPUT_ELEM_ID}
          data-testid={"testid-" + INPUT_ELEM_ID}
          type="text"
          className={"form-control" + cssState}
          placeholder="Delegates who can edit this request (required)"
          aria-label="Delegates who can edit this request (required)"
          onChange={getDelegateToSearch}
          onKeyDown={checkInputAction}
          disabled={props.readOnlyMode}
        />
        {props.readOnlyMode ? <></> : plusButton}
      </div>
      <EidSuggestion
        inputID={INPUT_ELEM_ID}
        eidSearchTrigger={eidSearchTrigger}
        searchToolsService={props.searchToolsService}
        addUserMethod={addDelegate}
      />
      <div>
        <div
          className={
            "form-control" + cssState + " " + props.hidden_delegate_div
          }
        ></div>
        <div
          id="ctc-kw-error-feedback"
          className={"invalid-feedback " + " " + props.adjust_error_msg}
        >
          {statusMessage}
        </div>
      </div>
      <div
        id="ctc-selected-delegates"
        data-testid="testid-ctc-selected-delegates"
        className={props.selected_delegates_div}
        tabIndex="0"
      >
        {delegatesList}
        {isLoading ? (
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div
              className="spinner-delegates spinner-border spinner-border"
              role="status"
              id="delegates-spinner"
              data-testid="delegates-spinner"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <> </>
        )}
      </div>
      <Toast
        toastText="EID copied to the clipboard!"
        toastKey="copyToClipboard"
      />
    </div>
  );
};

export default DelegateField;
